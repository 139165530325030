import { createReducer } from '@reduxjs/toolkit'
import { ImageHeights, ImageSRCs, ImageTypes, ImageWidths } from 'constants/imageData'

import {
  addEdit,
  addInput,
  clearEdits,
  copyEdit,
  removeEdit,
  removeInput,
  selectEdit,
  setBackground,
  setDimensions,
  setEdit,
  setFilter,
  setInput,
  setPosEdit,
} from './actions'
import { Edit, EditType, FilterType, InputType } from './types'

const currentTimestamp = () => new Date().getTime()

const initialInputs = { urls: ImageSRCs, widths: ImageWidths, heights: ImageHeights, types: ImageTypes }

const initialEdits = {
  [0]: {
    key: 0,
    data: { id: 0, width: 100, height: 1, x: 0, y: 0 },
    x: 50,
    y: 50,
    z: 0,
    rotate: 0,
    scale: 1,
    opacity: 1,
    flipX: 1,
    flipY: 1,
    filter: { filter: FilterType.NONE, value: 0 },
  },
}

interface EditState {
  nextEditKey: number

  colorBG?: string

  selectedBG?: number

  selectedEdit?: number

  edits: { [key: number]: Edit }

  inputs: { urls: string[]; widths: number[]; heights: number[]; types: boolean[] }

  dimensions: { width: number; height: number; widthClient: number; heightClient: number }
}

const initialState: EditState = {
  nextEditKey: 1,
  colorBG: undefined,
  selectedBG: undefined,
  selectedEdit: undefined,
  edits: initialEdits,
  inputs: initialInputs,
  dimensions: { width: 1024, height: 1024, widthClient: 0, heightClient: 0 },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addEdit, (state, { payload: { ratio, inputId } }) => {
      if (inputId === undefined || state.inputs.urls.length < inputId) return
      const key = state.nextEditKey
      const height = ratio
        ? Number(ratio.toFixed(4))
        : inputId !== undefined
        ? Number((state.inputs.heights[inputId] / state.inputs.widths[inputId]).toFixed(4))
        : 1

      state.edits[key] = {
        key,
        data: { id: inputId, width: 50, height, x: 0, y: 0 },
        x: 50,
        y: 50,
        z: 0,
        rotate: 0,
        scale: 1,
        opacity: 1,
        flipX: 1,
        flipY: 1,
        filter: { filter: FilterType.NONE, value: 0 },
      }
      state.selectedEdit = key
      state.nextEditKey = key + 1
    })
    .addCase(clearEdits, (state) => {
      for (let i = 11; i < state.inputs.urls.length; i++) {
        URL.revokeObjectURL(state.inputs.urls[i])
      }

      state.edits = initialEdits
      state.inputs = initialInputs
      state.selectedBG = undefined
      state.selectedEdit = undefined
      state.nextEditKey = 1
    })
    .addCase(copyEdit, (state, { payload: { key } }) => {
      if (!key || key >= state.nextEditKey) return
      const selected = state.edits[key]
      const nextKey = state.nextEditKey

      state.edits[nextKey] = {
        key: nextKey,
        data: selected.data,
        x: selected.x + 2,
        y: selected.y + 2,
        z: selected.z,
        rotate: selected.rotate,
        scale: selected.scale,
        opacity: selected.opacity,
        flipX: selected.flipX,
        flipY: selected.flipY,
        filter: selected.filter,
      }
      state.selectedEdit = nextKey
      state.nextEditKey = nextKey + 1
    })
    .addCase(removeEdit, (state, { payload: { key } }) => {
      if (key === 0) {
        state.edits[0] = initialEdits[0]
      }
      delete state.edits[key]
    })
    .addCase(selectEdit, (state, { payload: { key } }) => {
      if (key && key >= state.nextEditKey) return
      state.selectedEdit = key
    })
    .addCase(setEdit, (state, { payload: { type, value } }) => {
      const key = state.selectedEdit
      if (key !== undefined) {
        if (type === EditType.IMAGEID) {
          if (state.inputs.urls.length <= value) return
          state.edits[key].data.id = value
        }
        if (type === EditType.WIDTH) {
          const current = state.edits[key].data
          const heightCurrent = current.width * current.height
          state.edits[key].data.width = Number(value.toFixed(2))
          state.edits[key].data.height = Number((heightCurrent / value).toFixed(4))
        }
        if (type === EditType.HEIGHT) {
          state.edits[key].data.height = Number(value.toFixed(4))
        }
        if (type === EditType.CUT_X) {
          const current = state.edits[key].data
          const heightCurrent = current.width * current.height
          const newWidth = current.width + current.x - value
          state.edits[key].data.width = Number(newWidth.toFixed(2))
          state.edits[key].data.height = Number((heightCurrent / newWidth).toFixed(4))
          state.edits[key].data.x = Number(value.toFixed(2))
        }
        if (type === EditType.CUT_Y) {
          state.edits[key].data.y = Number(value.toFixed(2))
        }
        if (type === EditType.X) {
          state.edits[key].x = Number(value.toFixed(3))
        }
        if (type === EditType.Y) {
          state.edits[key].y = Number(value.toFixed(3))
        }
        if (type === EditType.Z) {
          state.edits[key].z = Math.round(value)
        }
        if (type === EditType.ROTATE) {
          state.edits[key].rotate = Number(value.toFixed(1))
        }
        if (type === EditType.SCALE) {
          state.edits[key].scale = Number(value.toFixed(4))
        }
        if (type === EditType.OPACITY) {
          state.edits[key].opacity = Number(value.toFixed(2))
        }
        if (type === EditType.FLIP) {
          if (value) {
            state.edits[key].flipY *= -1
          } else {
            state.edits[key].flipX *= -1
          }
        }
      }
    })
    .addCase(setFilter, (state, { payload: { type, value } }) => {
      const key = state.selectedEdit
      if (key !== undefined) {
        state.edits[key].filter = { filter: type, value: Number(value.toFixed(0)) }
      }
    })
    .addCase(setPosEdit, (state, { payload: { x, y } }) => {
      const key = state.selectedEdit
      if (key) {
        state.edits[key].x = Number(x.toFixed(2))
        state.edits[key].y = Number(y.toFixed(2))
      }
    })
    .addCase(addInput, (state, { payload: { data, width, height, isVector } }) => {
      state.inputs.urls.push(data)
      state.inputs.widths.push(width)
      state.inputs.heights.push(height)
      state.inputs.types.push(isVector)
    })
    .addCase(setInput, (state, { payload: { type, key, value } }) => {
      if (type === InputType.URL && typeof value === 'string') {
        URL.revokeObjectURL(state.inputs.urls[key])
        state.inputs.urls[key] = value
      }
      if (type === InputType.TYPE && typeof value === 'number') {
        state.inputs.types[key] = value === 0 ? false : true
      }
      if (type === InputType.WIDTH && typeof value === 'number') {
        state.inputs.widths[key] = value
      }
      if (type === InputType.HEIGHT && typeof value === 'number') {
        state.inputs.heights[key] = value
      }
    })
    .addCase(removeInput, (state, { payload: { key } }) => {
      URL.revokeObjectURL(state.inputs.urls[key])
      delete state.inputs.urls[key]
      delete state.inputs.types[key]
      delete state.inputs.widths[key]
      delete state.inputs.heights[key]
    })
    .addCase(setBackground, (state, { payload: { key } }) => {
      if (typeof key === 'string') {
        if (key === '') state.colorBG = undefined
        state.colorBG = key
        return
      }

      if (key !== undefined && key >= state.inputs.urls.length) return

      if (key === undefined) {
        state.edits[0] = initialEdits[0]
      } else {
        state.edits[0] = {
          key: 0,
          data: { id: key, width: 100, height: state.inputs.heights[key] / state.inputs.widths[key], x: 0, y: 0 },
          x: 50,
          y: 50,
          z: 0,
          rotate: 0,
          scale: 1,
          opacity: 1,
          flipX: 1,
          flipY: 1,
          filter: { filter: FilterType.NONE, value: 0 },
        }
      }

      state.selectedBG = key
    })
    .addCase(setDimensions, (state, { payload: { width, height, widthClient, heightClient } }) => {
      state.dimensions = { width, height, widthClient, heightClient }
    })
)
