import { Icon } from 'react-feather'
import styled, { css } from 'styled-components/macro'

const IconHoverText = styled.span`
  color: ${({ theme }) => theme.text2};
  position: absolute;
  top: 28px;
  border-radius: 8px;
  transform: translateX(-50%);
  opacity: 0;
  write-space: no-wrap;
  flex: no-wrap;
  font-size: 12px;
  padding: 4px;
  left: 10px;
`

const IconStyles = css`
  background-color: ${({ theme }) => theme.primary1};
  display: inline-block;
  cursor: pointer;
  position: relative;
  height: 32px;
  min-width: 32px;
  color: ${({ theme }) => theme.primary2};
`

const IconBlockLink = styled.a`
  ${IconStyles};
  border-radius: 12px;
  :hover {
    background-color: ${({ theme }) => theme.bg3};
    ${IconHoverText} {
      opacity: 1;
    }
  }
  transition: background-color 200ms linear;
`

const IconBlockButton = styled.button`
  ${IconStyles};
  border-radius: 12px;
  border: none;
  outline: none;
  :hover {
    background-color: ${({ theme }) => theme.bg3};
    ${IconHoverText} {
      opacity: 1;
    }
  }
  transition: background-color 200ms linear;
`

const IconBorderButton = styled.button<{ active?: boolean }>`
  ${IconStyles};
  color: ${({ color, theme }) => color ?? theme.primary2};
  border-radius: 9999px;
  border: 2px solid ${({ active, theme }) => (active ? theme.primary4 : theme.bg4)};
  outline: none;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
    ${IconHoverText} {
      opacity: 1;
    }
  }
  transition: border 200ms linear;
`

const IconWrapper = styled.span`
  position: absolute;
  transform: translate(-50%, -50%);
  min-width: 80%;
  height: 80%;
`
interface BaseProps {
  Icon: Icon
  size?: number
  active?: boolean
}
interface IconLinkProps extends React.ComponentPropsWithoutRef<'a'>, BaseProps {}
interface IconButtonProps extends React.ComponentPropsWithoutRef<'button'>, BaseProps {}

const IconBlock = (props: React.ComponentPropsWithoutRef<'a' | 'button'>) => {
  if ('href' in props) {
    return <IconBlockLink {...props} />
  }
  // ignoring 'button' 'type' conflict between React and styled-components
  // @ts-ignore
  return <IconBlockButton {...props} />
}

const IconButton = ({ Icon, size, children, ...rest }: IconButtonProps | IconLinkProps) => (
  <IconBlock {...rest} style={size ? { width: `${size}px`, height: `${size}px` } : {}}>
    <IconWrapper>
      <Icon strokeWidth={2} size="100%" />
      <IconHoverText>{children}</IconHoverText>
    </IconWrapper>
  </IconBlock>
)

export default IconButton

export const IconButtonBorder = ({ Icon, active, size, children, ...rest }: IconButtonProps) => (
  <IconBorderButton
    active={active}
    {...rest}
    style={size ? { minWidth: `${size}px`, width: `${size}px`, height: `${size}px` } : {}}
  >
    <IconWrapper>
      <Icon strokeWidth={2} size="100%" />
      <IconHoverText>{children}</IconHoverText>
    </IconWrapper>
  </IconBorderButton>
)
