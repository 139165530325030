import { AutoColumn } from '../../components/Column'
import { EditorDisclaimer } from '../../components/Disclaimer'
import EditPanel from '../../components/EditPanel'
import MemeHeader from '../../components/meme/MemeHeader'
import AppBody from '../AppBody'

export default function Meme() {
  return (
    <>
      <AppBody id="user-page" margin="-3rem">
        <AutoColumn gap="sm" justify="center" style={{ width: '100%' }}>
          <MemeHeader />
          <EditPanel />
        </AutoColumn>
      </AppBody>
      <EditorDisclaimer />
      <div style={{ height: '96px' }} />
    </>
  )
}
