import { AutoColumn } from 'components/Column'
import { useCallback, useState } from 'react'
import { ChevronUp, Plus } from 'react-feather'
import styled from 'styled-components/macro'

import { RectCV } from '../../state/eye/actions'
import { TYPE } from '../../theme'
import { Form } from '../Button/UploadButton'
import { StyledNavLink } from '../home/styleds'
import IdInputPanel from '../IdInputPanel'
import { RowBetween } from '../Row'

const InputPanel = styled(AutoColumn)`
  gap: 0px;
  position: relative;
  width: 100%;
  height: 56px;
`

const FixedContainer = styled(AutoColumn)<{ hide?: boolean }>`
  width: 100%;
  height: ${({ hide }) => (hide ? '0px' : '95%')};
  gap: 0px;
  align-items: center;
  overflow: hidden;
  transition: height 200ms linear;
`

const PanelWrapper = styled.div<{ show: boolean }>`
  height: ${({ show }) => (show ? '220px' : '0px')};
  width: 100%;
  z-index: ${({ show }) => (show ? '100' : '0')};
  padding: -8px 12px 16px 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  border-radius: 0px;
  background-color: ${({ theme }) => theme.primary2};
  border-top: 2px solid ${({ theme }) => theme.bg3};
  transition: height 200ms linear;
`

const ShowIconWrapper = styled.div<{ active?: number }>`
  background-color: ${({ theme }) => theme.primary2};
  border: 2px solid ${({ theme }) => theme.bg3};
  border-bottom: none;
  min-width: 40px;
  padding-right: 4px;
  border-top-right-radius: 28px;
  height: 32px;
  z-index: 100;
  position: absolute;
  bottom: ${({ active }) => (active ? '218px' : '0px')};
  left: -4px;
  transition: bottom 200ms linear;
`

const ShowIcon = styled(ChevronUp)<{ active?: number }>`
  stroke: ${({ theme }) => theme.primary4};
  margin-top: -4px;
  min-width: 36px;
  min-height: 40px;
  cursor: pointer;
  scale: ${({ active }) => (active ? '1 -1' : '1 1')};
  :hover {
    opacity: 0.6;
  }
  transition: rotate 300ms linear, scale 400ms linear;
`

const AddIcon = styled(Plus)<{ active?: number; show?: number }>`
  stroke: ${({ theme }) => theme.primary4};
  min-width: 36px;
  min-height: 36px;
  position: absolute;
  bottom: ${({ show }) => (show ? '184px' : '-36px')};
  right: 4px;
  cursor: pointer;
  rotate: ${({ active }) => (active ? '45deg' : '0deg')};
  :hover {
    opacity: 0.6;
  }
  transition: rotate 300ms linear, opacity 300ms linear, bottom 200ms linear;
`

const LogoIconWrapper = styled.div`
  stroke: ${({ theme }) => theme.primary2};
  fill: ${({ theme }) => theme.primary1};
  width: 100%;
`

const PlusUpload = styled(Plus)`
  stroke: ${({ theme }) => theme.bg3};
  width: 92px;
  height: 92px;
`

const ImportOption = styled.div<{ backgroundColor?: string }>`
  padding-top: 4px;
  background: ${({ backgroundColor, theme }) => (backgroundColor ? backgroundColor : theme.advancedBG)};
  border: 2px solid ${({ theme }) => theme.bg3};
  cursor: pointer;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  width: 100%;
  height: 40px;
  overflow: hidden;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
    transition: border 200ms linear;
  }
`

interface ImportPanelProps {
  eyes?: RectCV[]
  indexes: number[]
  input?: { urls: string; widths: number; heights: number }
  handleAddInput: (value: string, width: number, height: number) => void
  handleSetIndex: (index: number, value: number) => void
  isDownload?: boolean
}

export default function ImportPanel({
  eyes,
  indexes,
  input,
  handleAddInput,
  handleSetIndex,
  isDownload,
}: ImportPanelProps) {
  //const isMobile = useIsMobile()
  const cv = window.cv

  const [menu, setMenu] = useState(!isDownload ? 'upload' : 'download')

  const handleSetIndex0 = useCallback(
    (value?: number | string) => {
      handleSetIndex(0, Number(value) ?? 0)
    },
    [handleSetIndex]
  )

  const handleSetIndex1 = useCallback(
    (value?: number | string) => {
      handleSetIndex(1, Number(value) ?? 0)
    },
    [handleSetIndex]
  )

  return (
    <PanelWrapper id="ImportPanel" show={menu !== ''}>
      <TYPE.Main fontSize={18} textAlign="center">
        {isDownload && menu != 'upload' ? 'Download' : 'Upload'} Image
      </TYPE.Main>
      <FixedContainer hide={input && menu === 'download'}>
        <FixedContainer justify="center" hide={(eyes && eyes.length ? eyes.length : 0) < 2}>
          <TYPE.Main textAlign="center">Move Eyes</TYPE.Main>
          <RowBetween style={{ width: '100%', gap: '16px' }}>
            <InputPanel>
              <TYPE.Main textAlign="center">Eye 1</TYPE.Main>
              <IdInputPanel
                value={indexes[0]}
                maxValue={eyes && eyes.length ? eyes.length - 1 : 0}
                onChange={handleSetIndex0}
                className="EDIT-ID-INPUT"
              />
            </InputPanel>
            <InputPanel>
              <TYPE.Main textAlign="center">Eye 2</TYPE.Main>
              <IdInputPanel
                value={indexes[1]}
                onChange={handleSetIndex1}
                maxValue={eyes && eyes.length ? eyes.length - 1 : 0}
                className="EDIT-BADGE-INPUT"
              />
            </InputPanel>
          </RowBetween>
        </FixedContainer>
        <FixedContainer justify="center" hide={!cv}>
          <ImportOption>
            <div
              style={{
                opacity: '0',
                position: 'relative',
                left: '36px',
                right: '0',
              }}
            >
              <Form className="EYES" onLoad={handleAddInput} />
            </div>
            <TYPE.White mt="-26px">SELECT OR DROP</TYPE.White>
          </ImportOption>
        </FixedContainer>
        <FixedContainer justify="center" hide={!!cv}>
          <StyledNavLink to="/eye">
            <TYPE.White>Reload</TYPE.White>
          </StyledNavLink>
          <TYPE.Red>OpenCV load error</TYPE.Red>
        </FixedContainer>
      </FixedContainer>
      <FixedContainer justify="center" gap="sm" hide={!(input && menu == 'download')}>
        <TYPE.Main textAlign="center">1. Right click / click and hold image</TYPE.Main>
        <TYPE.Main textAlign="center">2. Click on save image as...</TYPE.Main>
        <TYPE.Main fontSize={12} textAlign="center">
          OpenCV works not perfectly, you can set the eye points if it not set correctly!
        </TYPE.Main>
      </FixedContainer>
      {isDownload ? (
        <AddIcon
          active={menu === 'download' ? 1 : undefined}
          show={menu !== '' ? 1 : undefined}
          onClick={() => setMenu(menu === 'upload' ? 'download' : 'upload')}
        />
      ) : null}
      <ShowIconWrapper active={menu !== '' ? 1 : undefined}>
        <ShowIcon
          active={menu !== '' ? 1 : undefined}
          onClick={() => setMenu(menu === '' ? (input ? 'upload' : 'download') : '')}
        />
      </ShowIconWrapper>
    </PanelWrapper>
  )
}
