import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { AppState } from '../index'
import { addInput, RectCV, removeInput, setFounded, setIndex } from './actions'

export function useEyeState(): AppState['eye'] {
  return useAppSelector((state) => state.eye)
}

export function useEyeActionHandlers(): {
  onAddInput: (data: string, width: number, height: number) => void
  onRemoveInput: () => void
  onSetFounded: (eyes?: RectCV[]) => void
  onSetIndex: (index: number, value: number) => void
} {
  const dispatch = useAppDispatch()

  const onAddInput = useCallback(
    (data: string, width: number, height: number) => {
      dispatch(addInput({ data, width, height }))
    },
    [dispatch]
  )

  const onRemoveInput = useCallback(() => {
    dispatch(removeInput())
  }, [dispatch])

  const onSetFounded = useCallback(
    (eyes?: RectCV[]) => {
      dispatch(setFounded({ eyes }))
    },
    [dispatch]
  )
  const onSetIndex = useCallback(
    (index: number, value: number) => {
      dispatch(setIndex({ index, value }))
    },
    [dispatch]
  )

  return {
    onAddInput,
    onRemoveInput,
    onSetFounded,
    onSetIndex,
  }
}
