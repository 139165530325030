import { createAction } from '@reduxjs/toolkit'

export type PopupContent = {
  txn: {
    hash: string
  }
}

export enum ApplicationModal {
  SETTINGS,
  USER,
  MENU,
}

export const setOpenModal = createAction<ApplicationModal | null>('application/setOpenModal')
export const addPopup = createAction<{ key?: string; removeAfterMs?: number | null; content: PopupContent }>(
  'application/addPopup'
)
export const removePopup = createAction<{ key: string }>('application/removePopup')

export const updateDisclaimerEyes = createAction<{ eyesDisclaimerKnowledged: boolean }>('user/updateDisclaimerEyes')

export const updateDisclaimerEditor = createAction<{ editorDisclaimerKnowledged: boolean }>(
  'user/updateDisclaimerEditor'
)

export const updateMatchesDarkMode = createAction<{ matchesDarkMode: boolean }>('user/updateMatchesDarkMode')

export const updateUserDarkMode = createAction<{ userDarkMode: boolean }>('user/updateUserDarkMode')
