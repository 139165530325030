import { useState } from 'react'
import { ChevronDown } from 'react-feather'
import styled from 'styled-components/macro'

import { useIsMobile } from '../../hooks/useIsMobile'
import { EditMap } from '../../state/edit/types'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'

const ImportPanel = styled(AutoColumn)<{ hide?: boolean }>`
  position: relative;
  gap: 4px;
  align-items: center;
  height: ${({ hide }) => (hide ? '0' : 'auto')};
  justify-content: center;
  width: 100%;
  overflow: hidden;
`

const HideContainer = styled(AutoColumn)<{ hide?: boolean }>`
  width: 100%;
  margin-top: -6px;
  height: ${({ hide }) => (hide ? '0' : '20px')};
  overflow: hidden;
  transition: height 300ms linear;
`

const InputPanel = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: auto;
`

const InputImage = styled.img<{ open: boolean; active: boolean; isMobile?: boolean }>`
  border: 2px solid ${({ active, theme }) => (active ? theme.green : theme.bg4)};
  height: ${({ open }) => (open ? '64px' : '0px')};
  border-radius: 4px;
  cursor: pointer;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
  }
  transition: border 200ms linear, height 300ms linear;
`

const OpenIcon = styled(ChevronDown)<{ active?: string }>`
  stroke: ${({ theme }) => theme.primary4};
  justify-self: center;
  cursor: pointer;
  margin-top: -4px;
  margin-bottom: -12px;
  transition: rotate 300ms linear;
  scale: ${({ active }) => active && '1 -1'};
  :hover {
    opacity: 0.6;
  }
  transition: scale 300ms linear, opacity 200ms linear;
`

export function EditsPanel({
  hasEdits,
  selected,
  edits,
  inputs,
  onSelectEdit,
}: {
  selected?: number
  hasEdits: boolean
  edits: EditMap
  inputs: { urls: string[]; widths: number[]; heights: number[]; types: boolean[] }
  onSelectEdit: (key?: number) => void
}) {
  const [isOpen, setOpen] = useState(false)
  const isMobile = useIsMobile()

  function getInputs() {
    const list: JSX.Element[] = []
    for (const key in edits) {
      if (key !== '0') {
        list.push(
          <InputImage
            key={`IMAGE-EDIT-${key}`}
            src={inputs.urls[edits[key].data.id]}
            active={selected === Number(key)}
            isMobile={isMobile}
            open={isOpen}
            onClick={() => onSelectEdit(Number(key))}
          />
        )
      }
    }
    return list
  }

  return (
    <ImportPanel hide={!hasEdits}>
      <HideContainer hide={!isOpen}>
        <TYPE.Main fontSize={16} textAlign="center">
          LAYERS
        </TYPE.Main>
      </HideContainer>
      <InputPanel>{getInputs()}</InputPanel>
      <OpenIcon size={36} active={isOpen ? 'true' : undefined} onClick={() => setOpen(!isOpen)} />
    </ImportPanel>
  )
}
