import dextoolsLogoUrl from 'assets/svg/dextools.svg'
import swapLogoUrl from 'assets/svg/pumpfunIcon.svg'
import solanaLogoUrl from 'assets/svg/solanaIcon.svg'
import telegramLogoUrl from 'assets/svg/telegram.svg'
import xLogoUrl from 'assets/svg/xIcon.svg'

import { SupportedChainId } from './chains'

interface LinkInfo {
  readonly infoLink: string
  readonly label: string
  readonly colorLabel?: string
  readonly logoUrl: string
}

type SocialInfo = {
  readonly [label: string]: LinkInfo
}

type ExplorerInfo = {
  readonly [chainId: number]: LinkInfo
}

export const SOCIAL_INFO: SocialInfo = {
  Telegram: {
    infoLink: 'https://t.me/TradeDoveMEME',
    label: 'Telegram',
    logoUrl: telegramLogoUrl,
  },
  Twitter: {
    infoLink: 'https://x.com/TradeDove',
    label: 'X',
    logoUrl: xLogoUrl,
  },
  Swap: {
    infoLink: 'https://pump.fun.org/token',
    label: 'pump.fun',
    logoUrl: swapLogoUrl,
  },
  Dextools: {
    infoLink: 'https://www.dextools.io/app/ethereum/pair-explorer/',
    label: 'Dextools',
    logoUrl: dextoolsLogoUrl,
  },
}

export const EXPLORER_TOKEN_INFO: ExplorerInfo = {
  [SupportedChainId.ETHEREUM]: {
    infoLink: 'https://solscan.io/address/0x0B5Afdc93A86062A49063EA96AE944D7F966B6AE',
    label: 'SolScan',
    logoUrl: solanaLogoUrl,
  },
}
