import React, { ChangeEvent, forwardRef, KeyboardEvent, useCallback, useState } from 'react'
import styled from 'styled-components/macro'

const StyledInput = styled.input<{ error?: boolean; maxHeight?: string; fontSize?: string; backgroundColor?: string }>`
  position: relative;
  flex: 1 1 auto;
  padding-left: 8px;
  max-height: ${({ maxHeight }) => maxHeight ?? '32px'};
  height: ${({ maxHeight }) => maxHeight && '100%'};
  align-items: 'center';
  width: 100%;
  white-space: nowrap;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
  transition: background-color 500ms linear;
  border-style: solid;
  border: none;
  outline: none;
  border-radius: 12px;
  color: ${({ error, theme }) => (error ? theme.red1 : theme.white)};
  -webkit-appearance: none;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;

  font-size: ${({ fontSize }) => fontSize ?? '16px'};

  ::placeholder {
    color: ${({ theme }) => theme.text2};
    font-size: 16px;
  }
`

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'> {
  onEnter: (value: string) => void
  isValid?: (value: string) => boolean
  error?: boolean
  maxHeight?: string
  fontSize?: string
  backgroundColor?: string
  className?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, maxHeight, backgroundColor, onEnter, isValid, className = '0', ...rest }: InputProps, ref) => {
    const [{ value, error }, setValue] = useState<{ value: string; error: boolean }>({
      value: '',
      error: false,
    })

    const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value
      setValue({
        value: input,
        error: false,
      })
    }, [])

    const handleEnter = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          const validInput = isValid ? isValid(value) : true
          if (validInput) {
            onEnter(value)
          }
          setValue({
            value: validInput ? '' : value,
            error: !validInput,
          })
        }
      },
      [onEnter, value, isValid]
    )

    return (
      <StyledInput
        {...rest}
        ref={ref}
        maxHeight={maxHeight}
        backgroundColor={backgroundColor}
        value={value}
        onChange={handleInput}
        onKeyDown={handleEnter}
        error={error}
        id={`Text-Input-${className}`}
        // universal input options
        type="text"
        autoComplete="off"
        autoCorrect="off"
        placeholder={placeholder ?? 'Paste image link here'}
        minLength={1}
        spellCheck="false"
      />
    )
  }
)
Input.displayName = 'Input'

const MemoizedInput = React.memo(Input)
export { MemoizedInput as Input }
