export enum SupportedChainId {
  ETHEREUM = 1,
  OPTIMISM = 10,
  SEPOLIA = 11155111,
  OP_SEPOLIA = 11155420,
}

export const L1_CHAIN_IDS = [SupportedChainId.ETHEREUM, SupportedChainId.SEPOLIA] as const

export const L2_CHAIN_IDS = [SupportedChainId.OPTIMISM, SupportedChainId.OP_SEPOLIA] as const

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number]

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number]

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  //return !!chainId && !!SupportedChainId[chainId];
  return !!chainId && !!SupportedChainId[chainId]
}

export function isEthereum(chainId: number | null | undefined): chainId is SupportedChainId {
  return !!chainId && (chainId == SupportedChainId.ETHEREUM || chainId == SupportedChainId.SEPOLIA)
}

export function isOp(chainId: number | null | undefined): chainId is SupportedChainId {
  return !!chainId && (chainId == SupportedChainId.OPTIMISM || chainId == SupportedChainId.OP_SEPOLIA)
}
