export type EditMap = {
  [key: number]: Edit
}

export enum EditType {
  IMAGEID = 'IMAGEID',
  WIDTH = 'WIDTH',
  HEIGHT = 'HEIGHT',
  CUT_X = 'CUT_X',
  CUT_Y = 'CUT_Y',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  ROTATE = 'ROTATE',
  SCALE = 'SCALE',
  OPACITY = 'OPACITY',
  FLIP = 'FLIP',
}

export enum FilterType {
  NONE = 'NONE',
  BLUR = 'BLUR',
  BRIGHTNESS = 'BRIGHTNESS',
  CONTRAST = 'CONTRAST',
  GRAYSCALE = 'GRAYSCALE',
  SHADOW = 'DROP-SHADOW',
  ROTATE = 'HUE-ROTATE',
  INVERT = 'INVERT',
  SATURATE = 'SATURATE',
  SEPIA = 'SEPIA',
}

export enum InputType {
  URL = 'URL',
  WIDTH = 'WIDTH',
  HEIGHT = 'HEIGHT',
  TYPE = 'TYPE',
}

export interface Edit {
  key: number
  data: { id: number; width: number; height: number; x: number; y: number }
  x: number
  y: number
  z: number
  rotate: number
  scale: number
  opacity: number
  flipX: number
  flipY: number
  filter: { filter: FilterType; value: number }
}

export function GenerateFilter(filter: FilterType, value: number) {
  return filter === FilterType.NONE
    ? 'none'
    : `${
        filter === FilterType.BLUR
          ? 'blur('
          : filter === FilterType.BRIGHTNESS
          ? 'brightness('
          : filter === FilterType.CONTRAST
          ? 'contrast('
          : filter === FilterType.SHADOW
          ? 'drop-shadow('
          : filter === FilterType.GRAYSCALE
          ? 'grayscale('
          : filter === FilterType.ROTATE
          ? 'hue-rotate('
          : filter === FilterType.INVERT
          ? 'invert('
          : filter === FilterType.SATURATE
          ? 'saturate('
          : 'sepia('
      }${
        filter === FilterType.SHADOW
          ? '0 0 ' + value.toString() + 'px black'
          : value.toString() + (filter === FilterType.ROTATE ? 'deg' : filter === FilterType.BLUR ? 'px' : '%')
      })`
}
