import { AutoColumn } from 'components/Column'
import { useCallback, useState } from 'react'
import {
  ArrowUp,
  ChevronLeft,
  Code,
  Divide,
  Droplet,
  Image,
  Maximize,
  Minimize,
  RotateCw,
  Scissors,
  Star,
  Trash,
  X,
} from 'react-feather'
import styled from 'styled-components/macro'

import { ReactComponent as OPACITYICON } from '../../assets/svg/opacityIcon.svg'
import useTheme from '../../hooks/useTheme'
import { Edit, EditType, FilterType } from '../../state/edit/types'
import { TYPE } from '../../theme'
import { IconButtonBorder } from '../Button/IconButton'
import SlideButton, { SlideButton2 } from '../Button/SlideButton'
import IdInputPanel from '../IdInputPanel'
import { ImageImportPanel } from '../meme/ImageImportPanel'
import { RowBetween } from '../Row'
import FilterPanel from './FilterPanel'

const FixedContainer = styled(AutoColumn)`
  width: 100%;
  padding: 4px 8px 8px 8px;
  height: 220px;
  align-items: center;
`

const WidthButton = styled(AutoColumn)<{ active?: boolean }>`
  padding: 2px 4px 4px 4px;
  width: 100%;
  min-height: 16px;
  max-height: 40px;
  max-width: 256px;
  border-radius: 16px;
  border: 2px solid ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  :hover {
    opacity: 0.6;
  }
  transition: opacity 300ms linear;
`

const HideContainer = styled(AutoColumn)<{ hide?: boolean; height?: string }>`
  width: 100%;
  height: ${({ height, hide }) => (hide ? '0px' : height ?? '128px')};
  overflow: hidden;
  transition: height 200ms linear;
`

const HeaderRow = styled(RowBetween)`
  gap: 16px;
  padding: 0 4px 0 4px;
  max-width: 272px;
  position: relative;
  margin-bottom: 8px;
  height: 32px;
  width: 100%;
`

const ButtonLeft = styled(ChevronLeft)<{ active?: string }>`
  width: 32px;
  height: 32px;
  stroke: ${({ active, theme }) => (active ? theme.primary1 : theme.primary2)};
  cursor: ${({ active }) => (active ? 'pointer' : 'none')};
  :hover {
    opacity: 0.6;
  }
  transition: opacity 300ms linear;
`

const ButtonRight = styled(ButtonLeft)`
  rotate: 180deg;
`

const InfoRow = styled(RowBetween)`
  gap: 16px;
  padding: 0 4px 0 4px;
  max-width: 512px;
  position: relative;
  margin-bottom: 4px;
  height: 40px;
  width: 100%;
`

const MoveXIcon = styled(Code)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const MoveYIcon = styled(Code)`
  rotate: 90deg;
  width: 36px;
  height: 36px;
`

const MoveYIcon2 = styled(Code)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
  rotate: 90deg;
`

const MoveZIcon = styled(ArrowUp)`
  margin-top: -2px;
  border-bottom: 2px solid ${({ theme }) => theme.primary2};
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const RotateIcon = styled(RotateCw)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const ScaleIcon = styled(Minimize)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const CutXIcon = styled(Scissors)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const CutYIcon = styled(CutXIcon)`
  rotate: 270deg;
`

const FlipXIcon = styled(Divide)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
  border-radius: 9999px;
  border: 2px solid ${({ theme }) => theme.primary1};
`

const FlipYIcon = styled(FlipXIcon)`
  rotate: 90deg;
`

const ScaleBGIcon = styled(Maximize)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const CloseIcon = styled(X)`
  stroke: ${({ theme }) => theme.primary4};
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const IconWrapper = styled.div`
  padding: 4px 0 0 4px;
  border-radius: 12px;
  min-width: 32px;
  height: 32px;
  outline: 2px solid ${({ theme }) => theme.primary1};
`

const ColorIcon = styled(Droplet)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  justify-self: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const RemoveIcon = styled(Trash)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const ImageIcon = styled(Image)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const FilterIcon = styled(Star)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  justify-self: center;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const CutIcon = styled(Scissors)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  justify-self: center;
  //width: 44px;
  min-width: 24px;
  rotate: -45deg;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  transition: opacity 300ms linear;
`

const OpacityIcon = styled(OPACITYICON)`
  fill: ${({ theme }) => theme.primary2};
`

interface BackgroundPanelProps {
  editBG?: Edit
  colorBG?: string
  inputs: { urls: string[]; widths: number[]; heights: number[]; types: boolean[] }
  onSelectEdit: (key?: number) => void
  onSetEdit: (type: EditType, value: number) => void
  onSetFilter: (type: FilterType, value: number) => void
  onLoad: (value: string, width: number, height: number, isEdit?: boolean, isVector?: boolean) => void
  onBackground: (key?: number | string) => void
  handleChangeDimension: (width?: number, height?: number, widthClient?: number, heightClient?: number) => void
  dimensions: { width: number; height: number; widthClient: number; heightClient: number }
  isSVG?: boolean
  hasEdits?: boolean
}

export default function BackgroundPanel({
  editBG,
  colorBG,
  inputs,
  dimensions,
  handleChangeDimension,
  onSelectEdit,
  onSetEdit,
  onSetFilter,
  onLoad,
  onBackground,
  isSVG,
  hasEdits,
}: BackgroundPanelProps) {
  const theme = useTheme()

  const [mode, setMode] = useState(['select', ''])

  const isSetableBG = isSVG === true || editBG === undefined

  const selectedValue =
    editBG && mode[0] === 'cut'
      ? Number(
          (mode[1] === 'cut x'
            ? (editBG.data.x * dimensions.width) / 100
            : (editBG.data.y * dimensions.width) / 100
          ).toFixed(0)
        )
      : mode[0] === 'width'
      ? editBG
        ? Number(((editBG.data.width * editBG.scale * dimensions.width) / 100).toFixed(0))
        : dimensions.width
      : undefined

  const selectedValue2 =
    editBG && mode[0] === 'cut'
      ? Number(
          (mode[1] === 'cut x'
            ? (editBG.data.width * dimensions.width) / 100
            : (editBG.data.width * editBG.data.height * dimensions.width) / 100
          ).toFixed(0)
        )
      : mode[0] === 'width' && isSVG === undefined
      ? dimensions.height
      : undefined

  const maxValue =
    mode[0] === 'width'
      ? 4096
      : mode[1] === 'cut x'
      ? editBG
        ? dimensions.width
        : 0
      : mode[1] === 'cut y'
      ? editBG
        ? dimensions.height
        : 0
      : 1

  const minValue = 1
  const gapValue = mode[0] === 'width' ? 128 : 1

  const onCut1 = (value: number) => {
    if (!!editBG && mode[0] === 'cut') {
      if (mode[1] === 'cut y') {
        if (selectedValue !== undefined && selectedValue2 !== undefined) {
          const y = (value * 100) / dimensions.height
          onSetEdit(EditType.CUT_Y, y)
          onSetEdit(
            EditType.HEIGHT,
            ((selectedValue2 ?? maxValue) + (selectedValue ?? 0) - value) /
              ((editBG.data.width * dimensions.width) / 100)
          )
        }
      } else {
        onSetEdit(EditType.CUT_X, (value * 100) / dimensions.width)
      }
    }
  }

  const onCut2 = (value: number) =>
    !!editBG &&
    mode[0] === 'cut' &&
    (mode[1] === 'cut y'
      ? onSetEdit(EditType.HEIGHT, value / ((editBG.data.width * dimensions.width) / 100))
      : onSetEdit(EditType.WIDTH, (100 * value) / dimensions.width))

  const onScale = (value: number) => {
    if (!!editBG && mode[0] === 'width') {
      onSetEdit(EditType.SCALE, value / ((dimensions.width * editBG.data.width) / 100))
    }
  }

  const onChangeWidth = (value: number) => {
    if (!!editBG && mode[0] === 'width') {
      onSetEdit(EditType.HEIGHT, (editBG.data.width * editBG.data.height * dimensions.width) / 100 / value)
    }
  }

  const handleRGBSelect = useCallback(
    (value: number) => {
      onBackground(`hsl(${Math.round((359 * value) / 100)}, 100%, 50%)`)
    },
    [onBackground]
  )

  const handleBWSelect = useCallback(
    (value: number) => {
      const colorID = Math.round((255 * value) / 100)
      onBackground(`rgb(${colorID}, ${colorID}, ${colorID})`)
    },
    [onBackground]
  )

  const gradientRGB =
    'linear-gradient(to right, hsl(0, 100%, 50%), hsl(60, 100%, 50%), hsl(120, 100%, 50%), hsl(180, 100%, 50%), hsl(240, 100%, 50%), hsl(300, 100%, 50%), hsl(359, 100%, 50%))'

  const gradientBW = 'linear-gradient(to right, black, white)'

  return (
    <FixedContainer justify="center">
      <TYPE.Main fontSize={18} textAlign="center">
        {`BACKGROUND ${
          mode[0] === 'select'
            ? mode[1] === ''
              ? 'IMAGE'
              : 'COLOR'
            : mode[0] === 'width'
            ? isSVG === undefined
              ? 'DIMENSION'
              : 'SCALE'
            : mode[0] === 'filter'
            ? 'EFFECT'
            : 'CUTTER'
        }`}
      </TYPE.Main>

      <InfoRow>
        <IconWrapper>
          <ImageIcon
            active={mode[0] === 'select' && mode[1] === '' ? mode[0] : undefined}
            onClick={() => setMode(['select', ''])}
          />
        </IconWrapper>
        <IconWrapper>
          <CutIcon
            active={mode[0] === 'cut' ? mode[0] : undefined}
            onClick={() => setMode(mode[0] === 'cut' ? ['select', ''] : ['cut', 'cut x'])}
          />
        </IconWrapper>
        <WidthButton
          active={mode[0] === 'width'}
          onClick={() => setMode(mode[0] === 'width' ? ['select', ''] : ['width', ''])}
        >
          {editBG !== undefined ? (
            <TYPE.Main fontSize={12} textAlign="center">
              width: {Math.round((dimensions.width * editBG.data.width * editBG.scale) / 100)} height:{' '}
              {Math.round((dimensions.width * editBG.data.width * editBG.data.height * editBG.scale) / 100)}
            </TYPE.Main>
          ) : hasEdits ? (
            <TYPE.Main fontSize={12} textAlign="center">
              width: {dimensions.width} height: {dimensions.height}
            </TYPE.Main>
          ) : (
            <TYPE.Main fontSize={12} textAlign="center">
              {mode[0] === 'width' ? 'Select here' : 'scale here'}
            </TYPE.Main>
          )}
        </WidthButton>
        <IconWrapper>
          {mode[1] === 'color' && colorBG ? (
            <RemoveIcon
              active={mode[1] === 'color' ? mode[0] : undefined}
              onClick={() => {
                onBackground('')
              }}
            />
          ) : (
            <ColorIcon
              active={mode[1] === 'color' ? mode[0] : undefined}
              onClick={() => setMode(['select', mode[1] === 'color' ? '' : 'color'])}
            />
          )}
        </IconWrapper>
        <IconWrapper>
          <FilterIcon
            active={mode[0] === 'filter' ? mode[0] : undefined}
            onClick={() => setMode([mode[0] === 'filter' ? 'select' : 'filter', ''])}
          />
        </IconWrapper>
      </InfoRow>
      <HideContainer gap="md" hide={mode[0] !== 'cut'}>
        <RowBetween style={{ maxWidth: '512px', justifySelf: 'center' }}>
          <FlipXIcon
            active={editBG?.flipY === -1 ? mode[0] : undefined}
            onClick={() => editBG && onSetEdit(EditType.FLIP, 1)}
          />
          <IconButtonBorder
            active={mode[0] === 'cut' && mode[1] === 'cut x'}
            Icon={CutXIcon}
            size={48}
            onClick={() => setMode(['cut', 'cut x'])}
          />
          <CloseIcon onClick={() => setMode(['select', ''])} />
          <IconButtonBorder
            active={mode[0] === 'cut' && mode[1] === 'cut y'}
            Icon={CutYIcon}
            size={48}
            onClick={() => setMode(['cut', 'cut y'])}
          />
          <FlipYIcon
            active={editBG?.flipX === -1 ? mode[0] : undefined}
            onClick={() => editBG && onSetEdit(EditType.FLIP, 0)}
          />
        </RowBetween>
        <SlideButton2
          value={selectedValue}
          value2={selectedValue2}
          maxValue={maxValue}
          onChange={onCut1}
          onChange2={onCut2}
          className="Cutter-BG"
          placeholder={mode[1]}
        />
      </HideContainer>

      <HideContainer hide={mode[0] !== 'select' || mode[1] !== 'color'}>
        <SlideButton
          maxValue={100}
          onChange={handleBWSelect}
          className="BGColor"
          placeholder="Select Color"
          background={gradientBW}
        />
        <SlideButton
          maxValue={100}
          onChange={handleRGBSelect}
          className="BGColor"
          placeholder="Select Color"
          background={gradientRGB}
        />
      </HideContainer>
      <HideContainer hide={mode[0] !== 'width'}>
        <RowBetween style={{ width: '100%' }}>
          <AutoColumn style={{ width: '100%' }}>
            <TYPE.Main fontSize={16} textAlign="center">
              {isSVG === undefined ? 'width' : 'size'}
            </TYPE.Main>
            <IdInputPanel
              maxValue={maxValue}
              value={selectedValue}
              gapValue={gapValue}
              onChange={(value?: number | string) =>
                mode[0] === 'width' &&
                value &&
                (isSVG === undefined
                  ? handleChangeDimension(Number(value), undefined, undefined, undefined)
                  : editBG && onScale(Number(value)))
              }
              className="BG-WIDTH-INPUT"
              backgroundColor={theme.primary1}
              Icon={isSVG === true ? ScaleBGIcon : MoveXIcon}
            />
          </AutoColumn>
          {isSVG === undefined ? (
            <AutoColumn style={{ width: '100%' }}>
              <TYPE.Main fontSize={16} textAlign="center">
                height
              </TYPE.Main>
              <IdInputPanel
                value={selectedValue2}
                maxValue={maxValue}
                gapValue={gapValue}
                onChange={(value?: number | string) =>
                  mode[0] === 'width' && handleChangeDimension(undefined, Number(value), undefined, undefined)
                }
                className="BG-HEIGHT-INPUT"
                backgroundColor={theme.primary1}
                Icon={MoveYIcon2}
              />
            </AutoColumn>
          ) : null}
        </RowBetween>
      </HideContainer>
      <HideContainer hide={mode[0] !== 'filter'}>
        <FilterPanel className="BG" selected={editBG} onSetFilter={onSetFilter} />
      </HideContainer>
      <HideContainer hide={mode[0] !== 'select' || mode[1] !== ''}>
        <div style={{ marginTop: '-24px' }}>
          <ImageImportPanel
            className="BG"
            maxHeight="72px"
            selected={0}
            onBackground={onBackground}
            onLoad={onLoad}
            inputs={inputs}
            mode="bg"
            showRemove={!!editBG}
          />
        </div>
      </HideContainer>
    </FixedContainer>
  )
}
