import 'inter-ui'
import '@reach/dialog/styles.css'
import 'polyfills'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { unregister as unregisterServiceWorker } from 'utils/serviceWorker'

import App from './pages/App'
import store from './state'
import ThemeProvider, { ThemedGlobalStyle } from './theme'

const container = document.getElementById('root') as HTMLElement

//const Router = isBrowserRouterEnabled() ? BrowserRouter : HashRouter

createRoot(container).render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <ThemeProvider>
          <ThemedGlobalStyle />
          <App />
        </ThemeProvider>
      </HashRouter>
    </Provider>
  </StrictMode>
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  unregisterServiceWorker()
}
