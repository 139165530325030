import { createReducer } from '@reduxjs/toolkit'

import { addInput, RectCV, removeInput, setFounded, setIndex } from './actions'

interface MakerState {
  eyes?: RectCV[]
  indexes: number[]
  input?: { urls: string; widths: number; heights: number }
}

const initialState: MakerState = {
  eyes: undefined,
  input: undefined,
  indexes: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addInput, (state, { payload: { data, width, height } }) => {
      state.input = { urls: data, widths: width, heights: height }
      state.eyes = undefined
      state.indexes = []
    })
    .addCase(removeInput, (state) => {
      state.input && URL.revokeObjectURL(state.input.urls)
      state.input = undefined
      state.eyes = undefined
      state.indexes = []
    })

    .addCase(setFounded, (state, { payload: { eyes } }) => {
      state.eyes = eyes
      if (state.indexes.length === 0 && eyes && eyes.length) {
        state.indexes.push(0)
        if (eyes.length > 1) {
          state.indexes.push(1)
        }
      }
    })

    .addCase(setIndex, (state, { payload: { index, value } }) => {
      if (index == state.indexes.length) {
        state.indexes.push(value)
      } else {
        state.indexes[index] = value
      }
    })
)
