import { MouseEvent, TouchEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Maximize2, RotateCw } from 'react-feather'
import styled from 'styled-components/macro'

import useTheme from '../../hooks/useTheme'
import { EditMap, EditType, FilterType, GenerateFilter } from '../../state/edit/types'
import { IconButtonBorder } from '../Button/IconButton'
import Canvas from '../Canvas'

const InputsContainer = styled.div<{ height?: number; show?: boolean }>`
  top: 64px;
  right: 8px;
  left: 8px;
  height: ${({ height }) => height && height}px;
  opacity: ${({ show }) => (show ? '1' : '0')};
  position: absolute;
  overflow: hidden;
`

const ImageWrapper = styled.div`
  translate: -50% -50%;
  position: absolute;
  cursor: pointer;
  touch-action: none;
`

const ImageCutter = styled.div`
  position: absolute;
  overflow: hidden;
`

const RotateButtonWrapper = styled.div`
  top: 0;
  right: 0;
  margin-right: -28px;
  margin-top: -28px;
  position: absolute;
  border: 8px solid #0000;
`

const ScaleButtonWrapper = styled.div`
  bottom: 0;
  left: 0;
  margin-left: -26px;
  margin-bottom: -28px;
  position: absolute;
  border: 8px solid #0000;
`

interface InputContainerProps {
  selected?: number
  edits: EditMap
  inputs: { urls: string[]; widths: number[]; heights: number[]; types: boolean[] }
  onSelectEdit: (key?: number) => void
  onSetEdit: (type: EditType, value: number) => void
  onSetPosEdit: (x: number, y: number) => void
  dimensions: { width: number; height: number; widthClient: number; heightClient: number }
  show?: boolean
}

export default function InputContainer({
  dimensions,
  selected,
  edits,
  inputs,
  onSelectEdit,
  onSetEdit,
  onSetPosEdit,
  show,
}: InputContainerProps) {
  const theme = useTheme()
  const divRef = useRef<HTMLDivElement>(null)
  const rect = divRef.current?.getBoundingClientRect()

  const [onEdit, setOnEdit] = useState(false)

  const [isMove, setIsMove] = useState(false)

  const [isLoaded, setLoaded] = useState(false)

  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      if (!rect) return

      onSetPosEdit(
        (100 * (e.touches[0].clientX - rect.x)) / rect.width,
        (100 * (e.touches[0].clientY - rect.y)) / rect.height
      )
    },
    [onSetPosEdit, rect]
  )

  const handleMouseMove = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!rect) return
      onSetPosEdit((100 * (e.clientX - rect.x)) / rect.width, (100 * (e.clientY - rect.y)) / rect.height)
    },
    [onSetPosEdit, rect]
  )

  const handleMouseRotate = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!rect || !selected || !onEdit) return
      const width = (edits[selected].x * rect.width) / 100
      const height = (edits[selected].y * rect.height) / 100
      const diffX = e.clientX - rect.x - width
      const diffY = e.clientY - rect.y - height
      const degButton =
        Math.atan2(edits[selected].data.height * edits[selected].data.width, edits[selected].data.width) * Math.PI * 18
      let deg = Math.atan2(diffY, diffX) * Math.PI * 18 + degButton
      deg = deg < 0 ? deg + 360 : deg

      onSetEdit(EditType.ROTATE, deg)
    },
    [onSetEdit, rect, onEdit, edits, selected]
  )

  const handleTouchRotate = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      if (!rect || !selected) return

      const width = (edits[selected].x * rect.width) / 100
      const height = (edits[selected].y * rect.width) / 100
      const diffX = e.touches[0].clientX - rect.x - width
      const diffY = e.touches[0].clientY - rect.y - height
      const degButton =
        Math.atan2(edits[selected].data.height * edits[selected].data.width, edits[selected].data.width) * Math.PI * 18
      let deg = Math.atan2(diffY, diffX) * Math.PI * 18 + degButton
      deg = deg < 0 ? deg + 360 : deg

      onSetEdit(EditType.ROTATE, deg)
    },
    [onSetEdit, rect, edits, selected]
  )

  const handleTouchScale = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      if (!rect || !selected) return

      const width = (edits[selected].x * rect.width) / 100
      const height = (edits[selected].y * rect.height) / 100
      const baseWidth = Math.round((edits[selected].data.width * (rect?.width ?? 0)) / 100)
      const baseHeight = Math.round(baseWidth * edits[selected].data.height)
      const base = Math.sqrt((baseWidth / 2) ** 2 + (baseHeight / 2) ** 2)
      const deltaWidth = Math.sqrt(
        (e.touches[0].clientX - width - rect.x) ** 2 + (e.touches[0].clientY - height - rect.y) ** 2
      )
      const scale = deltaWidth / base
      onSetEdit(EditType.SCALE, scale < 0.1 ? 0.1 : scale > 4 ? 4 : scale)
    },
    [onSetEdit, rect, edits, selected]
  )

  const handleMouseScale = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!rect || !selected || !onEdit) return

      const width = (edits[selected].x * rect.width) / 100
      const height = (edits[selected].y * rect.height) / 100
      const baseWidth = Math.round((edits[selected].data.width * (rect?.width ?? 0)) / 100)
      const baseHeight = Math.round(baseWidth * edits[selected].data.height)
      const base = Math.sqrt((baseWidth / 2) ** 2 + (baseHeight / 2) ** 2)
      const deltaWidth = Math.sqrt((e.clientX - width - rect.x) ** 2 + (e.clientY - height - rect.y) ** 2) //pos.x - e.clientX

      const scale = deltaWidth / base
      onSetEdit(EditType.SCALE, scale < 0.1 ? 0.1 : scale > 4 ? 4 : scale)
    },
    [onSetEdit, rect, onEdit, edits, selected]
  )

  function getInputs() {
    const list: JSX.Element[] = []

    for (const key in edits) {
      if (key !== '0') {
        const edit = edits[Number(key)]
        const baseWidth = Math.round((edit.scale * 50 * (rect?.width ?? 0)) / 100)
        const baseHeight = Math.round((baseWidth * inputs.heights[edit.data.id]) / inputs.widths[edit.data.id])

        const isSelected = selected === edit.key
        list.push(
          <ImageWrapper id={`INPUT-IMAGE-${edit.key}`} key={edit.key}>
            <ImageCutter
              id={`IMAGE-CUTTER-${edit.key}`}
              key={edit.key}
              onTouchMove={isSelected && isMove ? handleTouchMove : () => null}
              onMouseMove={isSelected && isMove ? handleMouseMove : () => null}
              onTouchEnd={() => (isSelected ? setIsMove(false) : null)}
              onTouchStart={() => (isSelected ? setIsMove(true) : onSelectEdit(edit.key))}
              onMouseUp={() => (isSelected ? setIsMove(false) : null)}
              onMouseDown={() => (isSelected ? setIsMove(true) : onSelectEdit(edit.key))}
            >
              <Canvas
                data={inputs.urls[edit.data.id]}
                width={baseWidth}
                height={baseHeight}
                isFixed={true}
                className={`INPUT${key}`}
              />
            </ImageCutter>
            {isSelected && (
              <RotateButtonWrapper
                onMouseDown={() => setOnEdit(!onEdit)}
                onMouseMove={handleMouseRotate}
                onMouseUp={() => setOnEdit(false)}
                onTouchMove={handleTouchRotate}
              >
                <IconButtonBorder Icon={RotateCw} size={24} color={theme.primary4} />
              </RotateButtonWrapper>
            )}
            {isSelected && (
              <ScaleButtonWrapper
                onMouseDown={() => setOnEdit(!onEdit)}
                onMouseMove={handleMouseScale}
                onMouseUp={() => setOnEdit(false)}
                onTouchMove={handleTouchScale}
              >
                <IconButtonBorder Icon={Maximize2} size={24} color={theme.primary4} />
              </ScaleButtonWrapper>
            )}
          </ImageWrapper>
        )
      }
    }

    return list
  }

  useEffect(() => {
    Object.keys(edits).map((key) => {
      if (key !== '0') {
        const edit = edits[Number(key)]
        const inputWidth = Math.round((edit.scale * edit.data.width * (rect?.width ?? 0)) / 100)
        const inputHeight = Math.round(inputWidth * edit.data.height)

        const CanvasElement = document.getElementById(`CANVAS-INPUT${edit.key}`)
        if (CanvasElement) {
          CanvasElement.style.opacity = edit.opacity.toString()
          let filterValue = edit.filter.value
          if (edit.filter.filter === FilterType.BLUR || edit.filter.filter === FilterType.SHADOW) {
            filterValue *= dimensions.widthClient / dimensions.width
          }
          CanvasElement.style.filter = GenerateFilter(edit.filter.filter, filterValue)
          CanvasElement.style.marginLeft = `-${Math.round((edit.data.x * inputWidth) / edit.data.width)}px`
          CanvasElement.style.marginTop = `-${Math.round(
            (((edit.data.y * inputWidth) / edit.data.width) * (rect?.height ?? 1)) / (rect?.width ?? 1)
          )}px`
        }

        const ImageElement = document.getElementById(`INPUT-IMAGE-${edit.key}`)
        if (ImageElement) {
          ImageElement.style.width = `${inputWidth}px`
          ImageElement.style.height = `${inputHeight}px`
          ImageElement.style.top = `${edit.y}%`
          ImageElement.style.left = `${edit.x}%`
          ImageElement.style.rotate = `${edit.rotate}deg`
          if (selected === edit.key) {
            ImageElement.style.outline = `2px solid ${theme.green}`
            ImageElement.style.zIndex = '98'
          } else {
            ImageElement.style.outline = 'none'
            ImageElement.style.zIndex = edit.z.toString()
          }
        }

        const ImageCutter = document.getElementById(`IMAGE-CUTTER-${edit.key}`)
        if (ImageCutter) {
          ImageCutter.style.width = `${inputWidth}px`
          ImageCutter.style.height = `${inputHeight}px`
          ImageCutter.style.scale = `${edit.flipX} ${edit.flipY}`
        }
      }
    })
    !isLoaded && setLoaded(true)
  }, [edits, selected, theme, rect, isLoaded])

  return (
    <InputsContainer id="IMPORT-CONTAINER" ref={divRef} height={dimensions.heightClient} show={show}>
      <div onClick={() => onSelectEdit(undefined)} style={{ width: '100%', height: '100%' }} />
      {getInputs()}
    </InputsContainer>
  )
}
