export function isDevelopmentEnv(): boolean {
  return process.env.NODE_ENV === 'development'
}

function isStagingEnv(): boolean {
  // This is set in vercel builds and deploys from web/staging.
  return Boolean(process.env.REACT_APP_STAGING)
}

function isProductionEnv(): boolean {
  return process.env.NODE_ENV === 'production' && !isStagingEnv()
}

export function isAppTradeDoveMeme({ hostname }: { hostname: string }): boolean {
  return hostname === 'app.tradedove.meme'
}

export function isBrowserRouterEnabled(): boolean {
  if (isProductionEnv()) {
    if (
      isAppTradeDoveMeme(window.location) ||
      isLocalhost(window.location) // cypress tests
    ) {
      return true
    }
    return false // production builds *not* served through our domains or localhost, eg IPFS
  }
  return true // local dev builds
}

function isLocalhost({ hostname }: { hostname: string }): boolean {
  return hostname === 'localhost'
}

export function isSentryEnabled(): boolean {
  // Disable in e2e test environments
  if (isStagingEnv()) return false
  if (isProductionEnv() && !isAppTradeDoveMeme(window.location)) return false
  return process.env.REACT_APP_SENTRY_ENABLED === 'true'
}
