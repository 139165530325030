import { RowFixed } from 'components/Row'
import { Info, XCircle } from 'react-feather'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ExternalLink } from 'theme/components'

import { TYPE } from '../../theme/'

export const TransactionDetailsLabel = styled(TYPE.Black)`
  border-bottom: 1px solid ${({ theme }) => theme.primary4};
  padding-bottom: 0.5rem;
`

export const StyledBodyHeader = styled(RowFixed)`
  gap: 8px;
  text-align: center;
`

export const StyledInfo = styled(Info)<{ color?: string }>`
  height: 20px;
  width: 20px;
  margin-left: 4px;
  margin-right: 4px;
  color: ${({ color, theme }) => (color ? color : theme.text2)};
  :hover {
    opacity: 0.6;
  }
`

export const StyledX = styled(XCircle)`
  height: 20px;
  width: 20px;
  margin-left: 4px;
  margin-right: 4px;
  color: ${({ theme }) => theme.red};
  :hover {
    opacity: 0.6;
  }
`

const StyledLink = styled(ExternalLink)`
  padding: 0 8px 2px 8px;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  //background: ${({ theme }) => theme.advancedBG};
  border: 2px solid ${({ theme }) => theme.bg3};
  border-radius: 12px;
  justify-content: center;
  justify-self: center;
  font-size: 16px;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
  }
  transition: border 200ms linear;
`

export const StyledNavLink = styled(NavLink)<{ fontSize?: string }>`
  padding: 0 16px 4px 16px;
  cursor: pointer;
  border-radius: 9999px;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.advancedBG};
  border: 2px solid ${({ theme }) => theme.bg3};
  text-align: center;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
  }
  transition: border 200ms linear;
`
