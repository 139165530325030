import { Edit } from 'react-feather'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { StyledBodyHeader } from '../home/styleds'

const StyledHeader = styled.div`
  background-color: ${({ theme }) => theme.primary1};
  border: 2px solid ${({ theme }) => theme.bg4};
  border-radius: 24px;
  z-index: 1;
  justify-content: center;
  width: 128px;
  height: 48px;
  transition: border 300ms linear, background-color 300ms linear;
`

const StyledNavLink = styled(NavLink)`
  padding: 4px 0px 0px 4px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  text-decoration: none;
  width: 32px;
  :hover {
    opacity: 0.6;
  }
  transition: opacity 200ms linear;
`

const LinkLogo = styled(Edit)`
  stroke: ${({ theme }) => theme.primary2};
  width: 40px;
  height: 40px;
  margin-top: 2px;
  justify-content: self;
`

export default function MakerHeader() {
  return (
    <StyledBodyHeader>
      <StyledHeader>
        <StyledNavLink to="/meme">
          <LinkLogo />
        </StyledNavLink>
      </StyledHeader>
    </StyledBodyHeader>
  )
}
