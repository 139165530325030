import React, { useEffect, useRef, useState } from 'react'

import { Edit, EditMap, GenerateFilter } from '../../state/edit/types'
import { RectCV } from '../../state/eye/actions'

interface CanvasProps {
  selectedBG?: Edit
  height?: number
  width?: number
  data?: string
  className: string
  isFixed?: boolean
  maxWidth?: number
  x?: number
  y?: number
  show?: boolean
  onChange?: (width?: number, height?: number, widthClient?: number, heightClient?: number) => void
  dimensions?: { width: number; height: number; widthClient: number; heightClient: number }
}

export default function Canvas({
  selectedBG,
  data,
  show = true,
  className,
  width,
  height,
  x,
  y,
  isFixed,
  maxWidth,
  onChange,
  dimensions,
}: CanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const rect = canvasRef.current?.getBoundingClientRect()

  const img = new Image()
  const dpi = window.devicePixelRatio || 1

  img.addEventListener(
    'load',
    () => {
      const canvas = canvasRef.current
      if (canvas && data) {
        const context = canvasRef.current?.getContext('2d')
        if (context) {
          const widthImage = maxWidth
            ? img.width < img.height
              ? (maxWidth * img.width) / img.height
              : maxWidth
            : img.width
          const heightImage = maxWidth
            ? img.width < img.height
              ? maxWidth
              : (maxWidth * img.height) / img.width
            : img.height
          width = (width ? width : widthImage) * dpi
          height = height ? height * dpi : width ? (width * heightImage) / widthImage : heightImage * dpi
          canvas.width = selectedBG ? Math.round((width * selectedBG.data.width) / 100) : width
          canvas.height = selectedBG ? Math.round(canvas.width * selectedBG.data.height) : height
          let flipX = 1
          let flipY = 1
          if (selectedBG) {
            flipX = selectedBG.flipX
            flipY = selectedBG.flipY
            context.scale(selectedBG.flipX, selectedBG.flipY)

            context.filter = GenerateFilter(selectedBG.filter.filter, selectedBG.filter.value)
          }
          context.drawImage(img, -(x ?? 0) * dpi, -(y ?? 0) * dpi, flipX * width, flipY * height)
          if (onChange && selectedBG) {
            const rect = canvas.getBoundingClientRect()

            onChange(widthImage, heightImage, rect.width, rect.width * selectedBG.data.height)
          }
        }
      }
    },
    { once: true }
  )

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d')
    if (context && dimensions && !data) {
      context.clearRect(0, 0, dimensions.width * dpi, dimensions.height * dpi)
    }
  }, [canvasRef, data, dimensions, dpi])

  useEffect(() => {
    if (!onChange || data || !rect || !dimensions) return
    if (dimensions.widthClient !== 0) return

    onChange(1024, 1024, rect.width, rect.width)
  }, [rect, data, onChange, dimensions])

  if (data) {
    img.src = data
  }

  return (
    <canvas
      id={`CANVAS-${className}`}
      ref={canvasRef}
      style={{
        margin: isFixed ? '0 0 0 0' : '0 0 -4px 0',
        width: isFixed ? width : '100%',
        height: isFixed ? height ?? 'auto' : 'auto',
        pointerEvents: 'none',
        opacity: show ? '1' : '0',
        zIndex: selectedBG && selectedBG.z,
      }}
    />
  )
}

interface CanvasMultiProps {
  height?: number
  width?: number
  selectedBG?: number
  colorBG?: string
  edits: EditMap
  className: string
  inputs?: { urls: string[]; widths: number[]; heights: number[]; types: boolean[] }
  show?: boolean
  handleDownload: (url: string) => void
}

export function CanvasMulti({
  edits,
  width = 0,
  height = 0,
  selectedBG,
  colorBG,
  inputs = { urls: [], widths: [], heights: [], types: [] },
  show,
  handleDownload,
  className,
}: CanvasMultiProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [isRender, setIsRender] = useState(false)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    if (isRender || !canvas || !context || !show) return

    const loadImage = (url: string) => {
      return new Promise<HTMLImageElement>((resolve) => {
        const img = new Image()
        img.addEventListener(
          'load',
          () => {
            resolve(img)
          },
          { once: true }
        )
        img.src = url
      })
    }

    async function loadImages() {
      if (canvas && context && show) {
        width *= edits[0].scale
        height *= edits[0].scale
        const imgWidth = selectedBG !== undefined ? Math.round((width * edits[0].data.width) / 100) : width
        const imgHeight = selectedBG !== undefined ? Math.round(imgWidth * edits[0].data.height) : height
        if (canvas.width !== imgWidth) {
          canvas.width = imgWidth
        }
        if (canvas.height !== imgHeight) {
          canvas.height = imgHeight
        }
        context.clearRect(0, 0, width, height)

        if (colorBG) {
          context.fillStyle = colorBG
          context.fillRect(0, 0, width, height)
        }

        let isDrawing = true
        for (let zIndex = 0; isDrawing; zIndex++) {
          isDrawing = false
          const editsToDraw: EditMap = Object.assign({}, edits)
          for (const key in editsToDraw) {
            if (key != '0' || selectedBG !== undefined) {
              const edit = editsToDraw[key]
              if (edit.z === zIndex) {
                await loadImage(inputs.urls[edit.data.id]).then((img) => {
                  const baseWidth = Math.round((edit.scale * 50 * imgWidth) / 100)
                  const baseHeight = Math.round((baseWidth * img.height) / img.width)
                  const widthEdit = Math.round((imgWidth * edit.data.width * edit.scale) / 100)
                  const heightEdit = Math.round(widthEdit * edit.data.height)

                  context.globalAlpha = edit.opacity
                  context.filter = GenerateFilter(edit.filter.filter, edit.filter.value)
                  if (key === '0') {
                    context.translate(Math.round(width / 2), Math.round((height * edit.y) / 100))
                    context.scale(edit.flipX, edit.flipY)
                    const x = Math.round((edit.data.x * width) / 100)
                    const y = Math.round((edit.data.y * width) / 100)

                    context.drawImage(img, Math.round(-x - width / 2), Math.round(-y - height / 2), width, height)
                  } else if (widthEdit !== baseWidth || heightEdit !== baseHeight) {
                    context.translate(Math.round((imgWidth * edit.x) / 100), Math.round((imgHeight * edit.y) / 100))
                    context.scale(edit.flipX, edit.flipY)
                    if (edit.rotate % 360 !== 0) {
                      context.rotate((edit.rotate * edit.flipX * edit.flipY * Math.PI) / 180)
                    }
                    const x = (edit.scale * edit.data.x * imgWidth) / 100
                    const y = (edit.scale * edit.data.y * height) / 100
                    const ratioWidth = img.width / baseWidth
                    const ratioHeight = img.height / baseHeight
                    context.drawImage(
                      img,
                      Math.round(x * ratioWidth),
                      Math.round(y * ratioHeight),
                      Math.round(widthEdit * ratioWidth),
                      Math.round(heightEdit * ratioHeight),
                      -widthEdit / 2,
                      -heightEdit / 2,
                      widthEdit,
                      heightEdit
                    )
                  } else {
                    context.translate(Math.round((imgWidth * edit.x) / 100), Math.round((imgHeight * edit.y) / 100))
                    context.scale(edit.flipX, edit.flipY)
                    if (edit.rotate % 360 !== 0) {
                      context.rotate((edit.rotate * edit.flipX * edit.flipY * Math.PI) / 180)
                    }
                    context.drawImage(img, -widthEdit / 2, -heightEdit / 2, widthEdit, heightEdit)
                  }
                  context.resetTransform()
                })
                delete editsToDraw[key]
              } else if (edit.z > zIndex && !isDrawing) {
                isDrawing = true
              }
            }
          }
        }

        const png = canvas.toDataURL('image/png')
        handleDownload(png)
        setIsRender(true)
      }
    }

    loadImages()
  }, [canvasRef, edits, inputs, height, show, width, handleDownload, isRender])

  useEffect(() => {
    if (isRender && !show) {
      setIsRender(false)
    }
  }, [show, isRender])

  return (
    <canvas
      id={`CANVAS-MULTI-${className}`}
      ref={canvasRef}
      style={{
        margin: '0 0 -4px 0',
        width: '100%',
        height: 0,
      }}
    />
  )
}

interface CanvasMakerProps {
  data?: string
  indexes: number[]
  eyes?: RectCV[]
  className: string
  show?: boolean
  onFound: (eyes?: RectCV[]) => void
}

export function CanvasMaker({ data, show = true, className, onFound, indexes, eyes }: CanvasMakerProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const img = new Image()
  const dpi = window.devicePixelRatio || 1
  const [isClean, setIsClean] = useState(true)
  const cv = window.cv

  const loadImage = (url: string) => {
    return new Promise<HTMLImageElement>((resolve) => {
      const img = new Image()
      img.addEventListener(
        'load',
        () => {
          resolve(img)
        },
        { once: true }
      )
      img.src = url
      //}
    })
  }

  function loadOpenCv(onloadCallback: () => void) {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d', { willReadFrequently: true })
    const script = document.getElementById('cvscript')

    if (!canvas || !context || !script || !cv) return
    canvas.width = img.width
    canvas.height = img.height
    context.drawImage(img, 0, 0, img.width, img.height)
    if (!eyes) {
      const mat = cv.imread(img ?? '')
      const classifier = new cv.CascadeClassifier()
      const faces = new cv.RectVector()
      const cascadeFile = 'haarcascade_eye.xml' // path to xml
      if (classifier.load(cascadeFile)) {
        classifier.detectMultiScale(mat, faces, 1.1, 3, 0)
      }
      const rects = []
      for (let i = 0; i < faces.size(); i++) {
        const rect = faces.get(i)
        rects.push({ x: rect.x, y: rect.y, width: rect.width, height: rect.height })
      }
      onFound(rects)
      mat.delete()
      classifier.delete()
      faces.delete()
    }

    async function loadEyes() {
      const canvas = canvasRef.current
      const context = canvas?.getContext('2d')

      if (!context) return

      if (eyes && eyes.length > 1) {
        const face0 = eyes[indexes[0] ?? 0]
        const face1 = eyes[indexes[1] ?? 1]
        const isFront = face0.width / face1.width > 0.92 && face0.width / face1.width < 1.11
        const isSide = (!isFront && face0.width / face1.width < 0.68) || face0.width / face1.width > 1.4
        const isRightFrontFirst = isFront ? face0.x < face1.x : face0.width > face1.width
        const isViewLeft = !isFront ? (isRightFrontFirst ? face0.x > face1.x : face0.x < face1.x) : false
        const faceBig = isRightFrontFirst ? face0 : face1
        const pixelData = context.getImageData(faceBig.x + faceBig.width / 2, faceBig.y + faceBig.height, 1, 1).data
        const colorEyes = ((pixelData[0] << 16) | (pixelData[1] << 8) | pixelData[2]).toString(16)
        const fillEyes = `
				    <linearGradient id="eye" x1="0%" y1="100%" x2="100%" y2="100%" gradientTransform="rotate(-10 .5 .5)">
                        <stop offset="6%" stop-color="#${colorEyes}" stop-opacity="0" />
                        <stop offset="${isSide ? '23%' : '40%'}" stop-color="#${colorEyes}" stop-opacity="1" />
                    </linearGradient>`

        const eyesSVG = [
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 4 160 128" stroke="black" stroke-width="1.5" stroke-linejoin="round" stroke-linecap="round">
                    <defs>${fillEyes}</defs>
                    <g id="sokR">
                	    <path id="eyPR" fill="url(#eye)" d="M-28 32 Q40 0 84 28 Q92 32 98 48 Q120 54 92 76 Q108 100 80 104 Q64 112 20 112 Q8 112 0 116" />
                        <path id="fldR" fill="none" stroke-width="1" d="M98 48 Q80 36 36 36 Q0 36 -20 52 M80 104 Q64 104 36 100 Q0 96 -12 80" />
                	</g>
                        <path id="eyeR" fill="#d227f4" d="M-16 60 Q0 48 28 44 Q56 40 108 56 Q108 84 84 92 Q60 98 28 92 Q0 88 -16 60Z" />
                	    <clipPath id="cpiR"><use href="#eyeR" /></clipPath>
                		<g clip-path="url(#cpiR)">
                	    <g id="irsR">
                	        <path id="iriR" fill="#111" stroke="none" d="M46 40 c28 0 34 16 32 32 c0 12 -17 28 -32 28 c-24 0 -34 -16 -32 -28 c-4 -20 16 -34 32 -32Z" />
                	        <path id="mirR" fill="#fff" stroke="none" transform="${
                            isFront ? '' : 'translate(92) scale(-1 1)'
                          }" d="M40 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M52 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                	    </g>
                	</g>
                    </svg>`,
          `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 4 160 128" stroke="black" stroke-width="1.5" stroke-linejoin="round" stroke-linecap="round">
                    <defs>${fillEyes}</defs>
                    <g id="sokL">
                	    <path id="eyPL" fill="url(#eye)" d="M-28 32 Q40 4 84 28 Q96 36 98 48 Q116 56 92 76 Q108 100 80 104 Q64 112 20 112 Q8 112 0 116" />
                        <path id="fldL" fill="none" stroke-width="1" d="M98 48 Q60 38 36 38 Q0 36 -20 56 M80 104 Q64 104 36 102 Q0 96 -12 84" />
                	</g> 
                        <path id="eyeL" fill="#10efa7" d="M-16 68 Q0 50 28 48 Q88 48 104 64 Q104 84 84 96 Q56 100 28 96 Q4 92 -16 68Z" />
                	    <clipPath id="cpiL"><use href="#eyeL" /></clipPath>
                		<g clip-path="url(#cpiL)">
                	    <g id="irsL">
                	        <path id="iriL" fill="#111" stroke="none" d="M46 40 c24 0 32 12 30 28 c0 20 -16 32 -32 32 c-20 0 -32 -16 -32 -28 c0 -12 16 -32 34 -32Z" />
                	        <path id="mirL" fill="#fff" stroke="none" d="M52 56 q-8 0 -6 6 q0 8 6 6 q8 0 6 -6 q0 -8 -6 -6 M40 76 q-6 0 -4 4 q0 6 4 4 q6 0 4 -4 q0 -6 -4 -4 " />
                	    </g>
                	    </g>
                	</svg>`,
        ]

        const urlR = URL.createObjectURL(new Blob([eyesSVG[0]], { type: 'image/svg+xml;charset=utf-8' }))
        const urlL = URL.createObjectURL(new Blob([eyesSVG[1]], { type: 'image/svg+xml;charset=utf-8' }))
        await loadImage(isRightFrontFirst ? urlR : urlL).then((imgEye) => {
          context.translate(face0.x, face0.y)
          if (isFront) {
            if (isRightFrontFirst) {
              context.translate(faceBig.width * 0.8, faceBig.height / 5)
              context.scale(-1, 1)
            } else {
              context.translate(faceBig.width / 3, faceBig.height / 5)
            }
          } else {
            if (isViewLeft) {
              context.translate(faceBig.width * 0.8, 0)
              context.scale(-1, 1)
            }

            if (isSide) {
              context.translate(0, -faceBig.height / 5)
            }
            if (isRightFrontFirst) {
              context.translate(0, 0)
            } else {
              context.translate(isSide ? 0 : faceBig.width / 10, 0)
            }
          }
          context.drawImage(
            imgEye,
            Math.round(-face0.width / 2),
            Math.round(-faceBig.height / 2),
            Math.round(faceBig.width * (isFront ? 1.9 : isSide ? 2.5 : 2.2)),
            Math.round(faceBig.width * (isFront ? 1.9 : isSide ? 2.5 : 2.2) * 0.8)
          )
          context.resetTransform()
        })

        await loadImage(isRightFrontFirst ? urlL : urlR).then((imgEye) => {
          context.translate(face1.x, face1.y)
          if (isFront) {
            if (!isRightFrontFirst) {
              context.translate(faceBig.width * 0.8, faceBig.height / 5)
              context.scale(-1, 1)
            } else {
              context.translate(faceBig.width / 3, faceBig.height / 5)
            }
          } else {
            if (isViewLeft) {
              context.translate(faceBig.width * 0.8, 0)
              context.scale(-1, 1)
            }

            if (isSide) {
              context.translate(0, -faceBig.height / 5)
            }
            if (isRightFrontFirst) {
              context.translate(isSide ? 0 : faceBig.width / 10, 0)
            } else {
              context.translate(0, 0)
            }
          }
          context.drawImage(
            imgEye,
            Math.round(-face1.width / 2),
            Math.round(-faceBig.height / 2),
            Math.round(faceBig.width * (isFront ? 1.9 : isSide ? 2.5 : 2.2)),
            Math.round(faceBig.width * (isFront ? 1.9 : isSide ? 2.5 : 2.2) * 0.8)
          )
          context.resetTransform()
        })
        URL.revokeObjectURL(urlR)
        URL.revokeObjectURL(urlL)
      }
    }
    loadEyes()
  }

  img.addEventListener(
    'load',
    () => {
      loadOpenCv(() => null)
    },
    { once: true }
  )

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d', { willReadFrequently: true })
    if (data && isClean) {
      setIsClean(false)
    }
    if (isClean || data || !context) return

    context.clearRect(0, 0, 2048 * dpi, 2048 * dpi)
    setIsClean(true)
  }, [canvasRef, data, isClean, dpi])

  if (data) {
    img.src = data
  }

  return (
    <canvas
      id={`CANVAS-MAKER-${className}`}
      ref={canvasRef}
      style={{
        margin: '0 0 -4px 0',
        width: '100%',
        height: 'auto',
      }}
    />
  )
}
