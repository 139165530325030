import styled, { keyframes } from 'styled-components/macro'

import emojiFEye from '../../assets/images/normaleyes.png'
import emojiFSmile2 from '../../assets/images/smile2F.png'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'

const aniopa = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  54% {
    opacity: 0;
  }
  98% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ImageWrapper = styled.div<{ delay?: string }>`
  top: 100%;
  position: absolute;
  pointer-events: none;
  width: 100%;
  left: 50%;
  opacity: 0;
  margin-left: -32px;
  animation: ${aniopa} 2s linear infinite;
  animation-delay: ${({ delay }) => delay ?? '0s'};
`

const ImageWrapper2 = styled(ImageWrapper)`
  //margin-bottom: 50%;

  opacity: 1;
`

export function AdvancedEyeDetails() {
  return (
    <AutoColumn gap="md" justify="center" style={{ width: '100%' }}>
      <TYPE.Main>1. Upload an image with visible eyes</TYPE.Main>
      <TYPE.Main>2. Possibly move eyes</TYPE.Main>
      <TYPE.Main>3. Download image</TYPE.Main>
      <div style={{ width: '100%', opacity: '0.7' }}>
        <ImageWrapper>
          <img src={emojiFEye} style={{ width: '64px' }} />
        </ImageWrapper>
        <ImageWrapper2 delay="1000ms">
          <img src={emojiFSmile2} style={{ width: '64px' }} />
        </ImageWrapper2>
      </div>
    </AutoColumn>
  )
}
