import { transparentize } from 'polished'
import { ReactNode, useState } from 'react'
import styled from 'styled-components/macro'

import { StyledInfo, StyledX } from '../home/styleds'
import Popover, { PopoverProps } from '../Popover'

const TooltipContainer = styled.div`
  max-width: 352px;
  width: auto;
  height: 100%;
  padding: 8px 8px;
  font-weight: 400;
  word-break: break-word;
  background: ${({ theme }) => theme.primary1}F0;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.bg4};
  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.9, theme.shadow1)};
`

export const ResponsiveTooltipContainer = styled(TooltipContainer)<{ origin?: string; width?: string }>`
  padding: 8px;
  origin: ${({ origin }) => origin};
  width: ${({ width }) => width ?? 'auto'};
  min-width: 240px;
  max-width: 328px;
  overflow: hidden;
`

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: ReactNode
  disableHover?: boolean // disable the hover and content display
}

interface TooltipContentProps extends Omit<PopoverProps, 'content'> {
  content: ReactNode
  isOpen?: boolean
  onOpen?: () => void
  // whether to wrap the content in a `TooltipContainer`
  wrap?: boolean
  color?: string
  disableHover?: boolean // disable the hover and content display
}

function Tooltip({ text, ...rest }: TooltipProps) {
  return <Popover content={text && <TooltipContainer>{text}</TooltipContainer>} {...rest} />
}

function TooltipContent({ content, wrap = false, ...rest }: TooltipContentProps) {
  return <Popover content={wrap ? <TooltipContainer>{content}</TooltipContainer> : content} {...rest} />
}

/** Standard text tooltip. */
function MouseoverTooltip({ text, disableHover, children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = () => {
    if (!show) {
      setShow(true)
    }
  }
  const close = () => {
    if (show) {
      setShow(false)
    }
  }

  return (
    <Tooltip {...rest} show={show} text={disableHover ? null : text}>
      <div onClick={show ? close : open}>{children}</div>
    </Tooltip>
  )
}

/** Tooltip that displays custom content. */
export function MouseoverTooltipContent({
  content,
  children,
  disableHover,
  color,
  ...rest
}: Omit<TooltipContentProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = () => {
    if (!show) {
      setShow(true)
    }
  }
  const close = () => {
    if (show) {
      setShow(false)
    }
  }

  return (
    <TooltipContent {...rest} show={show} content={disableHover ? null : content}>
      <div
        style={{ cursor: 'pointer', display: 'inline-block', lineHeight: 0, padding: '0.25rem' }}
        onClick={show ? close : open}
      >
        {children === null ? show ? <StyledX /> : <StyledInfo color={color} /> : children}
      </div>
    </TooltipContent>
  )
}

interface PopoverContentProps extends Omit<PopoverProps, 'content'> {
  content: ReactNode
  // whether to wrap the content in a `TooltipContainer`
  wrap?: boolean
  color?: string
  origin?: string
  width?: string
  disableHover?: boolean // disable the hover and content display
}

function PopoverContent({ content, wrap = false, width, origin, ...rest }: PopoverContentProps) {
  return (
    <Popover
      content={
        wrap ? (
          <TooltipContainer>
            <ResponsiveTooltipContainer origin={origin} width={width}>
              {content}
            </ResponsiveTooltipContainer>
          </TooltipContainer>
        ) : (
          <ResponsiveTooltipContainer origin={origin} width={width}>
            {content}
          </ResponsiveTooltipContainer>
        )
      }
      {...rest}
    />
  )
}

/** Tooltip that displays custom content. */
export function PopoverClickContent({
  content,
  children,
  close,
  open,
  isOpen,
  disableHover,
  color,
  ...rest
}: { isOpen: boolean; open?: () => void; close?: () => void } & Omit<PopoverContentProps, 'show'>) {
  return (
    <PopoverContent {...rest} show={isOpen} content={disableHover ? null : content}>
      {children ?? (
        <div
          style={{ cursor: 'pointer', display: 'inline-block', lineHeight: 0, padding: '0.25rem' }}
          onClick={() => (isOpen ? close && close : open && open)}
        >
          isOpen ? <StyledX /> : <StyledInfo color={color} />
        </div>
      )}
    </PopoverContent>
  )
}
