import * as Sentry from '@sentry/react'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { useIsMobile } from 'hooks/useIsMobile'
import { PropsWithChildren, useState } from 'react'
import { ChevronUp } from 'react-feather'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'
import { isSentryEnabled } from 'utils/env'

const FallbackWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  text-transform: lowercase;
`

const BodyWrapper = styled.div<{ margin?: string }>`
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 1rem;
`

const StretchedRow = styled.div`
  display: flex;
  gap: 24px;

  > * {
    display: flex;
    flex: 1;
  }
`

const Code = styled.code`
  font-weight: 485;
  font-size: 12px;
  line-height: 16px;
  word-wrap: break-word;
  width: 100%;
  color: ${({ theme }) => theme.text2};
  overflow: scroll;
  max-height: calc(100vh - 450px);
`

const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primary2};
`

const CodeBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.primary1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  padding: 24px;
  gap: 10px;
  color: ${({ theme }) => theme.primary4};
`

const ShowMoreButton = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`

const ShowMoreIcon = styled(ChevronUp)<{ $isExpanded?: boolean }>`
  transform: ${({ $isExpanded }) => ($isExpanded ? 'none' : 'rotate(180deg)')};
`

const CodeTitle = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
`

const Fallback = ({ error, eventId }: { error: Error; eventId: string | null }) => {
  const [isExpanded, setExpanded] = useState(false)
  const isMobile = useIsMobile()

  // @todo: TYPE components should be responsive by default
  const [Title, Description] = isMobile ? [TYPE.White, TYPE.Body] : [TYPE.White, TYPE.Main]

  const showErrorId = isSentryEnabled() && eventId

  const showMoreButton = (
    <ShowMoreButton onClick={() => setExpanded((s) => !s)}>
      <TYPE.White>{isExpanded ? 'Show less' : 'Show more'}</TYPE.White>
      <ShowMoreIcon $isExpanded={isExpanded} width="20" height="20" />
    </ShowMoreButton>
  )

  const errorDetails = error.stack || error.message

  return (
    <FallbackWrapper>
      <BodyWrapper>
        <AutoColumn gap="lg">
          {showErrorId ? (
            <>
              <AutoColumn gap="sm">
                <Title textAlign="center">Something went wrong</Title>
                <Description textAlign="center" color="text2">
                  Request Provide ID
                </Description>
              </AutoColumn>
              <CodeBlockWrapper>
                <CodeTitle>
                  <TYPE.SubHeader>Error ID</TYPE.SubHeader>
                </CodeTitle>
                <Separator />
                {isExpanded && (
                  <>
                    <Code>{errorDetails}</Code>
                    <Separator />
                  </>
                )}
                {showMoreButton}
              </CodeBlockWrapper>
            </>
          ) : (
            <>
              <AutoColumn gap="sm">
                <Title textAlign="center">Something went wrong</Title>
                <Description textAlign="center" color="text2">
                  Request
                </Description>
              </AutoColumn>
              <CodeBlockWrapper>
                <CodeTitle>
                  <TYPE.White>Error details</TYPE.White>
                </CodeTitle>
                <Separator />
                <Code>{errorDetails.split('\n').slice(0, isExpanded ? undefined : 4)}</Code>
                <Separator />
                {showMoreButton}
              </CodeBlockWrapper>
            </>
          )}
          <StretchedRow>
            <ButtonPrimary onClick={() => window.location.reload()}>Reload</ButtonPrimary>
          </StretchedRow>
        </AutoColumn>
      </BodyWrapper>
    </FallbackWrapper>
  )
}

export default function ErrorBoundary({ children }: PropsWithChildren): JSX.Element {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, eventId }) => <Fallback error={error} eventId={eventId} />}
      beforeCapture={(scope) => {
        scope.setLevel('fatal')
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
