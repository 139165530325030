import asshead from '../assets/images/asshead.png'
import discoball from '../assets/images/discoball.png'
import doveass from '../assets/images/doveass.png'
import dovebunner from '../assets/images/dovebunner.png'
import dovesitback from '../assets/images/dovesitback.png'
import dovesit from '../assets/images/dovesitfront.png'
import dovesit2 from '../assets/images/dovesitfront2.png'
import dovefly from '../assets/images/fly.png'
import logo from '../assets/images/logo.png'
import normaleyes from '../assets/images/normaleyes.png'
import nyan from '../assets/images/nyan.png'
import dovepc1 from '../assets/images/pc1.png'
import dovepc2 from '../assets/images/pc2.png'
import dovepc3 from '../assets/images/pc3.png'
import doveshit1 from '../assets/images/shit.png'
import doveshit2 from '../assets/images/shiton.png'
import shitsolana from '../assets/images/shitsolana.png'
import emojismile from '../assets/images/smile2F.png'
import pumpfun from '../assets/images/swappumpfun.png'
import trash1 from '../assets/images/trash1.png'
import trash2 from '../assets/images/trash3.png'
import trash3 from '../assets/images/trash4.png'
import dovewalk from '../assets/images/walk.png'
import dovewave from '../assets/images/wave.png'

export const ImageSRCs: string[] = [
  logo,
  asshead,
  discoball,
  doveass,
  dovebunner,
  dovesitback,
  dovesit,
  dovesit2,
  dovefly,
  nyan,
  doveshit1,
  doveshit2,
  shitsolana,
  pumpfun,
  dovepc1,
  dovepc2,
  dovepc3,
  trash1,
  trash2,
  trash3,
  dovewalk,
  dovewave,
  emojismile,
  normaleyes,
]

export const ImageWidths: number[] = [
  512, 1080, 1080, 1080, 720, 1080, 1080, 720, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 1080,
  1080, 1080, 512, 512,
]

export const ImageHeights: number[] = [
  512, 1080, 1080, 1080, 1080, 1080, 1080, 1080, 540, 810, 1080, 1080, 1080, 810, 1080, 1080, 1080, 1080, 1080, 1080,
  1080, 1080, 512, 512,
]

export const ImageTypes: boolean[] = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
]
