import { useState } from 'react'
import { ChevronDown, Moon, Sun } from 'react-feather'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'

import Logo from '../../assets/images/logo.png'
import { useDarkModeManager } from '../../state/application/hooks'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import Menu from '../Menu'
import { RowBetween, RowFixed } from '../Row'

const HeaderFrame = styled(RowBetween)<{ hide: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  //height: 56px;
  height: ${({ hide }) => (hide ? '24' : '56')}px;
  top: ${({ hide }) => (hide ? '-56px' : '0')};
  position: relative;
  padding: 0 8px 0 8px;
  z-index: 99;
  position: relative;
  background-color: ${({ theme }) => theme.primary1}CC;
  transition: top 200ms linear, height 200ms linear;
`

const HeaderControls = styled(RowFixed)`
  gap: 4px;
  justify-self: flex-end;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  text-decoration: none;
  font-size: 24px;
  :hover {
    cursor: pointer;
  }
`

const DoveIcon = styled.img`
  height: 48px;
  width: 48px;
`

const ToggleMenuItem = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  opacity: 0.6;
  :hover {
    opacity: 0.4;
    cursor: pointer;
    text-decoration: none;
  }
  transition: opacity 300ms;
`

const HeaderLinks = styled(RowBetween)`
  max-width: 272px;
  display: grid;
  grid-auto-flow: column;
  overflow: auto;
  left: 0;
  background-color: ${({ theme }) => theme.primary2};
  border-top: 2px solid ${({ theme }) => theme.bg3};
  position: fixed;
  bottom: 0;
  grid-gap: 0px;
  max-width: 100%;
`

const StyledNavLink = styled(NavLink)<{ active?: string }>`
  ${({ theme }) => theme.flexRowNoWrap}
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ active, theme }) => (active ? theme.primary1 : theme.primary2)};
  color: ${({ active, theme }) => (active ? theme.white : theme.primary1)};
  font-size: 20px;
  font-weight: ${({ active }) => (active ? '600' : '500')};
  height: 96px;
  width: 100%;
  text-align: center;
  align-items: center;
  word-break: break-word;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  :hover {
    color: ${({ theme }) => theme.white};
  }
  transition: border 500ms linear, color 500ms linear;
  padding: 0 16px 0 16px;
  min-width: 100%;
`

const LogoWrapper = styled.div<{ active: boolean }>`
  stroke: ${({ active, theme }) => (active ? theme.white : theme.primary1)};
  fill: ${({ active, theme }) => (active ? theme.primary1 : theme.primary2)};
  :hover {
    stroke: ${({ theme }) => theme.white};
  }
`

const ShowIconWrapper = styled.div<{ active?: number }>`
  background-color: ${({ theme }) => theme.primary1}CC;
  width: 36px;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  height: 24px;
  z-index: 100;
  position: absolute;
  top: 56px;
  right: 16px;
`

const ShowIcon = styled(ChevronDown)<{ active?: number }>`
  stroke: ${({ theme }) => theme.primary4};
  margin-top: -8px;
  min-width: 36px;
  min-height: 36px;
  cursor: pointer;
  scale: ${({ active }) => (active ? '1 -1' : '1 1')};
  :hover {
    opacity: 0.6;
  }
  transition: rotate 300ms linear, scale 400ms linear;
`

export default function Header() {
  const [darkMode, setDarkMode] = useDarkModeManager()

  const logo: string = Logo

  const { pathname } = useLocation()

  const onEditPage = true

  const [showHeader, setShowHeader] = useState(false)

  return (
    <HeaderFrame hide={onEditPage && !showHeader}>
      <Title href=".">
        <DoveIcon src={logo} />
        <AutoColumn>
          <TYPE.White ml="2px" fontWeight={700}>
            Trade
          </TYPE.White>
          <TYPE.White ml="8px" mt="-14px" mb="6px" fontWeight={700}>
            Dove
          </TYPE.White>
        </AutoColumn>
      </Title>
      <HeaderControls>
        <ToggleMenuItem onClick={() => setDarkMode()}>
          {darkMode ? <Sun size={32} /> : <Moon size={32} />}
        </ToggleMenuItem>
        <Menu />
      </HeaderControls>
      {onEditPage ? (
        <ShowIconWrapper active={showHeader ? 1 : 0}>
          <ShowIcon active={showHeader ? 1 : 0} onClick={() => setShowHeader(!showHeader)} />
        </ShowIconWrapper>
      ) : null}
    </HeaderFrame>
  )
}
