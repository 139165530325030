import { Eye } from 'react-feather'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { StyledBodyHeader } from '../home/styleds'

const StyledHeader = styled.div`
  background-color: ${({ theme }) => theme.primary1};
  border: 2px solid ${({ theme }) => theme.bg4};
  border-radius: 24px;
  z-index: 1;
  justify-content: center;
  width: 128px;
  height: 48px;
  transition: border 300ms linear, background-color 300ms linear;
`

const StyledNavLink = styled(NavLink)`
  cursor: pointer;

  text-decoration: none;
  width: 32px;
  height: 100%;
  :hover {
    opacity: 0.6;
  }
  transition: opacity 200ms linear;
`

const LinkLogo = styled(Eye)`
  stroke: ${({ theme }) => theme.primary2};
  width: 44px;
  height: 44px;
  justify-content: self;
`

export default function MemeHeader() {
  return (
    <StyledBodyHeader>
      <StyledHeader>
        <StyledNavLink to="/eye">
          <LinkLogo />
        </StyledNavLink>
      </StyledHeader>
    </StyledBodyHeader>
  )
}
