import styled from 'styled-components/macro'

import { RectCV } from '../../state/eye/actions'
import { CanvasMaker } from '../Canvas'

const ImageContainer = styled.div<{ active?: boolean; height?: string }>`
  width: 100%;
  height: ${({ height }) => (height ? height : 'auto')};
  outline: 1px solid ${({ active, theme }) => (active ? theme.green : theme.primary1)};
  overflow: hidden;
  transition: outline 300ms linear;
`

interface ImagePanelProps {
  input?: { urls: string; widths: number; heights: number }
  isDownload?: boolean
  indexes: number[]
  onFound: (eyes?: RectCV[]) => void
  eyes?: RectCV[]
}

export default function ImagePanel({ input, isDownload, onFound, indexes, eyes }: ImagePanelProps) {
  return (
    <ImageContainer id="editimage" active={isDownload} height={input ? undefined : '256px'}>
      <CanvasMaker
        data={input ? input.urls : undefined}
        show={!isDownload}
        className="CONTAINER"
        onFound={onFound}
        indexes={indexes}
        eyes={eyes}
      />
    </ImageContainer>
  )
}
