import { AutoColumn } from 'components/Column'
import { useState } from 'react'
import { ChevronUp, Image, Maximize, Plus, Type } from 'react-feather'
import styled from 'styled-components/macro'

import { useIsMobile } from '../../hooks/useIsMobile'
import { useEditActionHandlers, useEditState } from '../../state/edit/hooks'
import { EditType } from '../../state/edit/types'
import { TYPE } from '../../theme'
import { IconButtonBorder } from '../Button/IconButton'
import { ImageImportPanel } from '../meme/ImageImportPanel'
import { RowBetween } from '../Row'
import BackgroundPanel from './BackgroundPanel'
import TextInputPanel from './TextInputPanel'
import TransformPanel from './TransformPanel'

const InputPanel = styled(AutoColumn)`
  gap: 0px;
  position: relative;
  width: 100%;
  height: 56px;
`

const FixedContainer = styled(AutoColumn)<{ hide?: boolean; height?: string }>`
  width: 100%;
  height: ${({ height, hide }) => (hide ? '0px' : height ?? '220px')};
  gap: 8px;
  align-items: center;
  overflow: hidden;
  transition: height 200ms linear;
`

const PanelWrapper = styled.div<{ show: boolean }>`
  height: ${({ show }) => (show ? '220px' : '0px')};
  width: 100%;
  z-index: ${({ show }) => (show ? '100' : '1')};
  padding: 0 12px 0 12px;
  position: fixed;
  left: 0;
  bottom: -2px;
  border-radius: 0px;
  background-color: ${({ theme }) => theme.primary2};
  border-top: 2px solid ${({ theme }) => theme.bg3};
  transition: height 200ms linear;
`

const ShowIconWrapper = styled.div<{ active?: number }>`
  background-color: ${({ theme }) => theme.primary2};
  border: 2px solid ${({ theme }) => theme.bg3};
  border-bottom: none;
  min-width: 48px;
  padding-left: 4px;
  border-top-left-radius: 28px;
  height: 36px;
  z-index: 100;
  position: absolute;
  bottom: ${({ active }) => (active ? '218px' : '0px')};
  right: -2px;
  transition: bottom 200ms linear;
`

const ShowIcon = styled(ChevronUp)<{ active?: number }>`
  stroke: ${({ theme }) => theme.primary4};
  margin-top: -4px;
  min-width: 32px;
  min-height: 40px;
  cursor: pointer;
  scale: ${({ active }) => (active ? '1 -1' : '1 1')};
  :hover {
    opacity: 0.6;
  }
  transition: rotate 300ms linear, scale 400ms linear;
`

const AddIcon = styled(Plus)<{ active?: number; show?: number }>`
  stroke: ${({ theme }) => theme.primary1};
  min-width: 36px;
  min-height: 36px;
  position: absolute;
  bottom: ${({ show }) => (show ? '184px' : '-36px')};
  right: 4px;
  cursor: pointer;
  rotate: ${({ active }) => (active ? '45deg' : '0deg')};
  :hover {
    opacity: 0.6;
  }
  transition: rotate 300ms linear, opacity 300ms linear, bottom 200ms linear;
`

const LogoIconWrapper = styled.div`
  stroke: ${({ theme }) => theme.primary2};
  fill: ${({ theme }) => theme.primary1};
  width: 100%;
`

const StyledDownload = styled.a`
  padding 4px;
  background-color: ${({ theme }) => theme.primary2};
  border: 2px solid ${({ theme }) => theme.bg3};
  padding-right: 4px;
  border-radius: 12px;
  text-decoration: none;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
  }
  transition: border 300ms linear;
`

interface ImportPanelProps {
  onSelectEdit: (key?: number) => void
  onSetEdit: (type: EditType, value: number) => void
  handleAddEdit: (inputId?: number) => void
  handleAddInput: (value: string, width: number, height: number, isEdit?: boolean, isVector?: boolean) => void
  handleChangeDimension: (width?: number, height?: number, widthClient?: number, heightClient?: number) => void
  dimensions: { width: number; height: number; widthClient: number; heightClient: number }
  isDownload?: boolean
  hasEdits: boolean
}

export default function ImportPanel({
  dimensions,
  handleAddInput,
  onSelectEdit,
  onSetEdit,
  handleAddEdit,
  handleChangeDimension,
  isDownload,
  hasEdits,
}: ImportPanelProps) {
  const isMobile = useIsMobile()

  const { edits, inputs, nextEditKey, selectedEdit, selectedBG, colorBG } = useEditState()

  const selected = selectedEdit !== undefined ? edits[selectedEdit] : undefined

  const { onCopyEdit, onSetFilter, onRemoveEdit, onRemoveInput, onSetBackground } = useEditActionHandlers()

  const [menu, setMenu] = useState(!hasEdits ? 'upload' : 'transform')

  return (
    <PanelWrapper id="ImportPanel" show={menu !== ''}>
      <FixedContainer
        height="144px"
        hide={(menu === 'bg' && selected?.key === 0) || (menu !== 'input' && menu !== 'bg') || isDownload}
        style={{ justifyContent: 'center' }}
      >
        <TYPE.Main fontSize={18} textAlign="center">
          IMPORT
        </TYPE.Main>
        <RowBetween style={{ gap: '48px', marginTop: '20px' }}>
          <IconButtonBorder Icon={Type} size={64} onClick={() => setMenu('text')} />
          <IconButtonBorder
            Icon={Maximize}
            size={64}
            onClick={() => {
              onSelectEdit(0)
              setMenu('bg')
            }}
          />
          <IconButtonBorder Icon={Image} size={64} onClick={() => setMenu('upload')} />
        </RowBetween>
      </FixedContainer>
      <FixedContainer hide={menu !== 'transform' || isDownload}>
        <TransformPanel
          selected={selected}
          selectedBG={selectedBG === undefined ? undefined : edits[0]}
          nextKey={nextEditKey}
          keys={Object.keys(edits)}
          inputs={inputs}
          onCopyEdit={onCopyEdit}
          onRemoveEdit={onRemoveEdit}
          onSelectEdit={onSelectEdit}
          onSetEdit={onSetEdit}
          onSetFilter={onSetFilter}
          dimensions={dimensions}
          hasEdits={hasEdits}
        />
      </FixedContainer>
      <FixedContainer hide={menu !== 'text' || isDownload}>
        <TextInputPanel
          selected={selected}
          selectedBG={selectedBG}
          nextKey={nextEditKey}
          dimensions={dimensions}
          inputs={inputs}
          onAddEdit={handleAddEdit}
          onLoad={handleAddInput}
          onRemoveEdit={onRemoveEdit}
          onRemoveInput={onRemoveInput}
          onSetEdit={onSetEdit}
        />
      </FixedContainer>
      <FixedContainer hide={menu !== 'upload' || isDownload}>
        {menu !== 'upload' || isDownload ? null : (
          <ImageImportPanel
            className="EDIT"
            maxHeight="116px"
            selected={selected?.key}
            onSelect={handleAddEdit}
            onBackground={onSetBackground}
            onLoad={handleAddInput}
            onRemove={onRemoveEdit}
            inputs={inputs}
            showRemove={selectedBG !== undefined}
          />
        )}
      </FixedContainer>
      <FixedContainer hide={menu !== 'bg' || selected?.key !== 0 || isDownload}>
        <BackgroundPanel
          editBG={selectedBG === undefined ? undefined : edits[0]}
          colorBG={colorBG}
          inputs={inputs}
          onSelectEdit={onSelectEdit}
          onBackground={onSetBackground}
          onLoad={handleAddInput}
          onSetEdit={onSetEdit}
          onSetFilter={onSetFilter}
          handleChangeDimension={handleChangeDimension}
          dimensions={dimensions}
          isSVG={selectedBG === undefined ? undefined : inputs.types[selectedBG]}
          hasEdits={hasEdits}
        />
      </FixedContainer>
      <FixedContainer justify="center" hide={!isDownload}>
        <TYPE.Main fontSize={18} textAlign="center">
          DOWNLOAD
        </TYPE.Main>
        <TYPE.Main textAlign="center">1. Right click / click and hold image</TYPE.Main>
        <TYPE.Main textAlign="center">2. Click on save image as...</TYPE.Main>
        <TYPE.Main fontSize={12} textAlign="center">
          The rendered image may look slightly different due to different resolutions of the imported images!
        </TYPE.Main>
      </FixedContainer>
      {!isDownload ? (
        <AddIcon
          active={menu !== 'transform' ? 1 : undefined}
          show={menu !== '' ? 1 : undefined}
          onClick={() => setMenu(menu === 'input' ? 'transform' : 'input')}
        />
      ) : null}
      <ShowIconWrapper active={menu !== '' ? 1 : undefined}>
        <ShowIcon
          active={menu !== '' ? 1 : undefined}
          onClick={() => {
            menu === 'bg' && onSelectEdit(undefined)
            setMenu(menu === '' ? (hasEdits ? 'transform' : 'input') : '')
          }}
        />
      </ShowIconWrapper>
    </PanelWrapper>
  )
}
