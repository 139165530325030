import { createReducer, nanoid } from '@reduxjs/toolkit'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'

import {
  addPopup,
  ApplicationModal,
  PopupContent,
  removePopup,
  setOpenModal,
  updateDisclaimerEditor,
  updateDisclaimerEyes,
  updateMatchesDarkMode,
  updateUserDarkMode,
} from './actions'

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

interface ApplicationState {
  readonly openModal: ApplicationModal | null
  readonly popupList: PopupList
  disclaimerEditorKnowledged: boolean
  disclaimerEyesKnowledged: boolean
  matchesDarkMode: boolean // whether the dark mode media query matches
  userDarkMode: boolean | null // the user's choice for dark mode or light mode
  timestamp: number
}

const currentTimestamp = () => new Date().getTime()

const initialState: ApplicationState = {
  openModal: null,
  popupList: [],
  disclaimerEditorKnowledged: false,
  disclaimerEyesKnowledged: false,
  userDarkMode: null,
  matchesDarkMode: false,
  timestamp: currentTimestamp(),
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setOpenModal, (state, action) => {
      state.openModal = action.payload
    })
    .addCase(addPopup, (state, { payload: { content, key, removeAfterMs = DEFAULT_TXN_DISMISS_MS } }) => {
      state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ])
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    })
    .addCase(updateDisclaimerEditor, (state, action) => {
      state.disclaimerEditorKnowledged = action.payload.editorDisclaimerKnowledged
      state.timestamp = currentTimestamp()
    })
    .addCase(updateDisclaimerEyes, (state, action) => {
      state.disclaimerEyesKnowledged = action.payload.eyesDisclaimerKnowledged
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserDarkMode, (state, action) => {
      state.userDarkMode = action.payload.userDarkMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateMatchesDarkMode, (state, action) => {
      state.matchesDarkMode = action.payload.matchesDarkMode
      state.timestamp = currentTimestamp()
    })
)
