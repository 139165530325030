import { OutlineCard } from 'components/Card'
import { SOCIAL_INFO } from 'constants/socialInfos'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { useContext, useRef } from 'react'
import { Send } from 'react-feather'
import { Link, NavLink } from 'react-router-dom'
import { useDarkModeManager } from 'state/application/hooks'
import styled, { ThemeContext } from 'styled-components/macro'

import { ReactComponent as Logo } from '../../assets/svg/logoIcon.svg'
import { ReactComponent as MenuIcon } from '../../assets/svg/menu.svg'
import { ReactComponent as Twitter } from '../../assets/svg/xIcon.svg'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ExternalLink } from '../../theme/components'

const StyledCard = styled(OutlineCard)`
  padding: 2px;
  border: 1px solid ${({ theme }) => theme.bg3};
  background-color: ${({ theme }) => theme.primary1}F1;
`

const StyledMenuIcon = styled(MenuIcon)<{ open?: boolean }>`
  rotate: 90deg;
  transition: rotate 500ms;
  opacity: ${({ open }) => (open ? '1' : '0.6')};
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  //height: 40px;
  width: 40px;
  background-color: Transparent;

  padding: 0.15rem 0.5rem;
  border-radius: 12px;

  :hover {
    cursor: pointer;
    outline: none;
    opacity: 0.6;
  }
  transition: opacity 300ms;

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  //text-align: center;
`

const MenuFlyout = styled.span`
  width: 88px;
  height: 280px;

  border: 1px solid ${({ theme }) => theme.bg5};
  border-radius: 16px;
  padding: 8px;
  display: flex;

  flex-direction: column;
`

const MenuContainer = styled.div<{ open?: boolean }>`
  height: ${({ open }) => (open ? '280px' : '0')};
  width: 80px;
  background-image: linear-gradient(${({ theme }) => theme.primary2 + 'd0'}, ${({ theme }) => theme.primary2 + 'd0'}),
    linear-gradient(45deg, #d227f4, #10efa7);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  right: 0;
  margin-right: -9px;
  flex-direction: column;
  position: absolute;
  top: 44px;
  transition: height 200ms linear;
  z-index: 120;
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 0.75rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
  text-decoration: none;
`

const InternalMenuItem = styled(Link)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const InternalLinkMenuItem = styled(InternalMenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const ToggleMenuItem = styled.button<{ isOpen?: boolean }>`
  background-color: transparent;
  border: none;
  display: flex;
  width: 100%;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ isOpen, theme }) => (isOpen ? theme.text2 : theme.text1)};
  :hover {
    color: ${({ isOpen, theme }) => (isOpen ? theme.text1 : theme.text2)};
    cursor: pointer;
    text-decoration: none;
  }
`

const OverlapIcon = styled.div`
  position: absolute;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  text-align: center;
  align-items: center;
  padding: 0.5rem 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  //justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  text-decoration: none;
  :hover,
  :focus {
    color: ${({ theme }) => theme.text3};
    cursor: pointer;
  }
  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primary1};
  }
`

export default function Menu() {
  const theme = useContext(ThemeContext)
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggle : undefined)

  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const twitterInfo = SOCIAL_INFO['Twitter']
  const telegramInfo = SOCIAL_INFO['Telegram']
  //const swapInfo = SOCIAL_INFO['Swap']
  //const dextoolsInfo = SOCIAL_INFO['Dextools']
  //const tokenInfo = EXPLORER_TOKEN_INFO[1]

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle} aria-label="Menu">
        <StyledMenuIcon open={open} />
      </StyledMenuButton>

      <MenuContainer open={open}>
        {open && (
          <MenuFlyout>
            <MenuItem href="https://tradedove.meme">
              <Logo opacity={0.8} width={48} height={48} stroke={theme.text2} fill={theme.primary2} />
            </MenuItem>
            <MenuItem href={twitterInfo.infoLink}>
              <Twitter opacity={0.8} width={48} height={48} strokeWidth="1.5" stroke={theme.text2} />
            </MenuItem>
            <MenuItem href={telegramInfo.infoLink}>
              <Send opacity={0.8} size={48} strokeWidth="1.5" />
            </MenuItem>
          </MenuFlyout>
        )}
      </MenuContainer>
    </StyledMenu>
  )
}
