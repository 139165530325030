import { AutoColumn } from 'components/Column'
import { useCallback, useState } from 'react'
import { Download, Plus, Trash, X } from 'react-feather'
import styled from 'styled-components/macro'

import { useEditActionHandlers, useEditState } from '../../state/edit/hooks'
import { EditType, InputType } from '../../state/edit/types'
import { IconButtonBorder } from '../Button/IconButton'
import UploadButton from '../Button/UploadButton'
import ImagePanel from '../ImagePanel'
import { RowBetween } from '../Row'
import { AdvancedEditDetails } from './AdvancedEditDetails'
import { EditsPanel } from './EditsPanel'
import ImportPanel from './ImportPanel'

const FixedContainer = styled(AutoColumn)`
  width: 100%;
  align-items: center;
`

const SelectContainer = styled.div`
  width: 100%;
  height: 150%;
  position: absolute;
`

const UploadWrapper = styled.div`
  padding: 6px;
  top: -28px;
  border: 8px solid ${({ theme }) => theme.bg4};
  border-radius: 9999px;
  position: absolute;
  height: 128px;
  margin-left: 24%;
  opacity: 0.2;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  transition: opacity 300ms linear;
`

const PlusUpload = styled(Plus)`
  stroke: ${({ theme }) => theme.bg3};
  width: 92px;
  height: 92px;
`

const HeaderButtonRow = styled(RowBetween)`
  align-items: center;
  position: absolute;
  top: 12px;
  height: 40px;
  width: 232px;
  margin-left: 16px;
  justify-self: center;
`

const HeaderButtonRight = styled.div<{ show?: boolean }>`
  //margin-left: -48px;
  position: absolute;
  height: 44px;
  border-bottom: 2px solid ${({ theme }) => theme.bg3};
  border-top: 2px solid ${({ theme }) => theme.bg3};
  border-radius: 24px;
  width: ${({ show }) => (show ? '96px' : '48px')};
  left: 50%;
  transform: ${({ show }) => (show ? 'translate(16px)' : 'translate(-64px)')};
  transition: border 400ms linear, background-color 400ms linear, transform 400ms linear, width 400ms linear;
`

const HeaderButtonLeft = styled.div<{ show?: boolean }>`
  position: absolute;
  height: 44px;
  border-bottom: 2px solid ${({ theme }) => theme.bg3};
  border-top: 2px solid ${({ theme }) => theme.bg3};
  border-radius: 24px;
  width: ${({ show }) => (show ? '96px' : '48px')};
  left: 50%;
  transform: ${({ show }) => (show ? 'translate(-128px)' : 'translate(0px)')};
  transition: border 400ms linear, background-color 400ms linear, transform 400ms linear, width 400ms linear;
`

export default function EditPanel() {
  const { edits, inputs, selectedEdit, selectedBG, colorBG, dimensions } = useEditState()

  const nextInputKey = inputs.urls.length

  const hasEdits = Object.keys(edits).length > 1

  const {
    onAddInput,
    onAddEdit,
    onClearEdits,
    onEditSelection,
    onSetDimensions,
    onSetEdit,
    onSetPosEdit,
    onSetInput,
    onSetBackground,
  } = useEditActionHandlers()

  //const [dimensions, setDimensions] = useState({ width: 1024, height: 1024, widthClient: 0, heightClient: 0 })

  const [isDownload, setIsDownload] = useState(false)

  const [downloadURL, setDownloadURL] = useState<string | undefined>(undefined)

  const handleSetDimension = useCallback(
    (width?: number, height?: number, widthClient?: number, heightClient?: number) => {
      const newDimensions = {
        width: Math.round(width != undefined ? width : dimensions.width),
        height: Math.round(height != undefined ? height : dimensions.height),
        widthClient: Math.round(widthClient != undefined ? widthClient : dimensions.widthClient),
        heightClient,
      }
      newDimensions.heightClient = Math.round(
        heightClient != undefined
          ? heightClient
          : newDimensions.width !== dimensions.width || newDimensions.height !== dimensions.height
          ? (dimensions.heightClient / (dimensions.height / dimensions.width)) *
            (newDimensions.height / newDimensions.width)
          : dimensions.heightClient
      )
      if (
        newDimensions.width === dimensions.width &&
        newDimensions.height === dimensions.height &&
        newDimensions.widthClient === dimensions.widthClient &&
        newDimensions.heightClient === dimensions.heightClient
      )
        return
      onSetDimensions(newDimensions.width, newDimensions.height, newDimensions.widthClient, newDimensions.heightClient)
    },
    [dimensions, onSetDimensions]
  )

  const handleSetEdit = useCallback(
    (type: EditType, value: number) => {
      onSetEdit(type, value)
    },
    [onSetEdit]
  )

  const handleAddEdit = useCallback(
    (inputId?: number) => {
      onAddEdit(undefined, inputId)
    },
    [onAddEdit]
  )

  const handleAddInput = useCallback(
    (value: string, width: number, height: number, isEdit?: boolean, isVector?: boolean) => {
      onAddInput(value, width, height, isVector)
      if (isEdit) {
        onAddEdit(height / width, nextInputKey)
      } else {
        onSetBackground(nextInputKey)
      }
    },
    [onAddEdit, onAddInput, nextInputKey, onSetBackground]
  )

  const handleSetInput = useCallback(
    (type: InputType, key: number, value: string | number) => {
      onSetInput(type, key, value)
    },
    [onSetInput]
  )

  const handleSetDownload = useCallback((url?: string) => {
    setDownloadURL(url)
  }, [])

  return (
    <FixedContainer gap="sm">
      <SelectContainer onClick={() => selectedEdit && onEditSelection(undefined)} />
      <HeaderButtonRow>
        <HeaderButtonLeft show={(hasEdits || selectedBG !== undefined) && !isDownload}>
          <div style={{ marginTop: '-4px' }}>
            <IconButtonBorder
              Icon={Trash}
              size={48}
              onClick={() => {
                onClearEdits()
                handleSetDimension(undefined, undefined, 0, 0)
              }}
            />
          </div>
        </HeaderButtonLeft>
        <HeaderButtonRight show={hasEdits}>
          <div style={{ marginTop: '-4px', marginLeft: '48px' }}>
            <IconButtonBorder
              Icon={isDownload ? X : Download}
              size={48}
              onClick={() => {
                setIsDownload(isDownload ? false : true)
                isDownload && setDownloadURL(undefined)
              }}
            />
          </div>
        </HeaderButtonRight>
      </HeaderButtonRow>
      <ImagePanel
        dimensions={dimensions}
        edits={edits}
        inputs={inputs}
        selected={selectedEdit}
        selectedBG={selectedBG}
        colorBG={colorBG}
        onSelectEdit={onEditSelection}
        onSetEdit={onSetEdit}
        onSetPosEdit={onSetPosEdit}
        handleChangeDimension={handleSetDimension}
        handleDownload={handleSetDownload}
        isDownload={isDownload}
        downloadURL={downloadURL}
        hasEdits={hasEdits}
      />

      {hasEdits ? (
        <EditsPanel
          selected={selectedEdit}
          hasEdits={hasEdits}
          edits={edits}
          inputs={inputs}
          onSelectEdit={onEditSelection}
        />
      ) : null}
      <ImportPanel
        dimensions={dimensions}
        handleAddEdit={handleAddEdit}
        handleAddInput={handleAddInput}
        onSelectEdit={onEditSelection}
        onSetEdit={onSetEdit}
        handleChangeDimension={handleSetDimension}
        isDownload={isDownload}
        hasEdits={hasEdits}
      />
      {!(hasEdits || selectedBG !== undefined) ? (
        <div style={{ position: 'absolute', justifySelf: 'center', top: '148px' }}>
          <UploadWrapper>
            <UploadButton
              height="156px"
              className="Container"
              onLoad={handleAddInput}
              inputs={inputs}
              onSelect={handleAddEdit}
              onBackground={onSetBackground}
            >
              <PlusUpload />
            </UploadButton>
          </UploadWrapper>
          <AdvancedEditDetails />
        </div>
      ) : null}
    </FixedContainer>
  )
}
