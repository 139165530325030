import React from 'react'
import styled from 'styled-components/macro'

const BodyWrapper = styled.main<{ margin?: string; maxWidth?: string }>`
  position: relative;
  padding: 8px 8px 8px 8px;
  margin-top: ${({ margin }) => margin ?? '0px'};
  max-width: ${({ maxWidth }) => maxWidth ?? '1024px'};
  width: 100%;
  background-color: ${({ theme }) => theme.primary2}BB;
  border: 1px solid ${({ theme }) => theme.bg4};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 24px;
  overflow: hidden;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({
  children,
  id,
  maxWidth,
  margin,
  ...rest
}: {
  children: React.ReactNode
  id?: string
  maxWidth?: string
  margin?: string
}) {
  return (
    <BodyWrapper id={id} maxWidth={maxWidth} margin={margin} {...rest}>
      {children}
    </BodyWrapper>
  )
}
