import { AutoColumn } from 'components/Column'
import { useState } from 'react'
import {
  ArrowUp,
  ChevronLeft,
  Code,
  Copy,
  Divide,
  Minimize,
  RefreshCw,
  RotateCw,
  Scissors,
  Star,
  ToggleLeft,
  Trash,
  X,
} from 'react-feather'
import styled from 'styled-components/macro'

import { ReactComponent as OPACITYICON } from '../../assets/svg/opacityIcon.svg'
import useTheme from '../../hooks/useTheme'
import { Edit, EditType, FilterType } from '../../state/edit/types'
import { TYPE } from '../../theme'
import { IconButtonBorder } from '../Button/IconButton'
import SlideButton, { SlideButton2 } from '../Button/SlideButton'
import IdInputPanel from '../IdInputPanel'
import { RowBetween } from '../Row'
import FilterPanel from './FilterPanel'

const FixedContainer = styled(AutoColumn)`
  width: 100%;
  padding: 0 4px 8px 4px;
  height: 220px;
  align-items: center;
`

const WidthButton = styled(AutoColumn)<{ active?: boolean }>`
  padding: 0 2px 2px 2px;
  width: 100%;
  min-height: 16px;
  max-height: 44px;
  max-width: 256px;
  border-radius: 16px;
  border: 2px solid ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  :hover {
    opacity: 0.6;
  }
  transition: opacity 300ms linear;
`

const HideContainer = styled(RowBetween)<{ hide?: boolean; height?: string }>`
  width: 100%;
  height: ${({ height, hide }) => (hide ? '0px' : height ?? '56px')};
  overflow: hidden;
  transition: height 200ms linear;
`

const HeaderRow = styled(RowBetween)`
  gap: 16px;
  padding: 0 4px 0 4px;
  max-width: 272px;
  position: relative;
  margin-bottom: 8px;
  user-select: none;
  height: 32px;
  width: 100%;
`

const ButtonLeft = styled(ChevronLeft)<{ active?: string }>`
  width: 32px;
  height: 32px;
  stroke: ${({ active, theme }) => (active ? theme.primary1 : theme.primary2)};
  cursor: ${({ active }) => (active ? 'pointer' : 'none')};
  :hover {
    opacity: 0.6;
  }
  transition: opacity 300ms linear;
`

const ButtonRight = styled(ButtonLeft)`
  rotate: 180deg;
`

const InfoRow = styled(RowBetween)`
  gap: 16px;
  padding: 0 4px 0 4px;
  max-width: 512px;
  position: relative;
  margin-bottom: 4px;
  height: 40px;
  width: 100%;
`

const MoveXIcon = styled(Code)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const MoveYIcon = styled(Code)`
  rotate: 90deg;
  width: 36px;
  height: 36px;
`

const MoveYIcon2 = styled(Code)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
  rotate: 90deg;
`

const MoveZIcon = styled(ArrowUp)`
  margin-top: -2px;
  border-bottom: 2px solid ${({ theme }) => theme.primary2};
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const RotateIcon = styled(RotateCw)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const ScaleIcon = styled(Minimize)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const CutXIcon = styled(Scissors)`
  stroke: ${({ theme }) => theme.primary2};
  width: 32px;
  height: 32px;
`

const CutYIcon = styled(CutXIcon)`
  rotate: 270deg;
`

const FlipXIcon = styled(Divide)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
  border-radius: 9999px;
  border: 2px solid ${({ theme }) => theme.primary1};
`

const FlipYIcon = styled(FlipXIcon)`
  rotate: 90deg;
`

const CloseIcon = styled(X)`
  stroke: ${({ theme }) => theme.primary4};
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const IconWrapper = styled.div`
  padding: 4px 0 0 4px;
  border-radius: 12px;
  min-width: 32px;
  height: 32px;
  outline: 2px solid ${({ theme }) => theme.primary1};
`

const CopyIcon = styled(Copy)`
  stroke: ${({ theme }) => theme.primary4};
  justify-self: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const RemoveIcon = styled(Trash)`
  stroke: ${({ theme }) => theme.primary4};
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const Rotate45Icon = styled(RefreshCw)`
  stroke: ${({ theme }) => theme.primary4};
  justify-self: center;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const FilterIcon = styled(Star)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  justify-self: center;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const SliderIcon = styled(ToggleLeft)<{ active?: string }>`
  stroke: ${({ active, theme }) => (active ? theme.yellow : theme.primary4)};
  justify-self: center;
  //width: 44px;
  min-width: 24px;
  scale: ${({ active }) => active ?? '-1 1'};
  min-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  transition: opacity 300ms linear, scale 200ms linear;
`

const OpacityIcon = styled(OPACITYICON)`
  fill: ${({ theme }) => theme.primary2};
`

interface TransformPanelProps {
  selected?: Edit
  selectedBG?: Edit
  nextKey: number
  keys: string[]
  inputs: { urls: string[]; widths: number[]; heights: number[]; types: boolean[] }
  onCopyEdit: (key: number) => void
  onRemoveEdit: (key: number) => void
  onSelectEdit: (key?: number) => void
  onSetEdit: (type: EditType, value: number) => void
  onSetFilter: (type: FilterType, value: number) => void
  dimensions: { width: number; height: number; widthClient: number; heightClient: number }
  hasEdits?: boolean
}

export default function TransformPanel({
  selected,
  selectedBG,
  nextKey,
  keys,
  inputs,
  dimensions,
  onCopyEdit,
  onRemoveEdit,
  onSelectEdit,
  onSetEdit,
  onSetFilter,
  hasEdits,
}: TransformPanelProps) {
  const theme = useTheme()

  const [mode, setMode] = useState(['slide', 'rotate'])

  let index: number | undefined
  if (selected?.key) {
    for (let i = 0; i < keys.length; i++) {
      if (selected.key == Number(keys[i])) index = i
    }
  }

  const selectedValue =
    selected && selected?.key !== 0
      ? Number(
          mode[1] === 'move x'
            ? ((selected.x * dimensions.width) / 100).toFixed(0)
            : mode[1] === 'move y'
            ? ((selected.y * dimensions.height) / 100).toFixed(0)
            : mode[1] === 'move z'
            ? selected.z
            : mode[1] === 'rotate'
            ? selected.rotate
            : mode[1] === 'scale'
            ? selected.scale
            : mode[1] === 'cut x'
            ? ((selected.data.x * dimensions.width) / 100).toFixed(0)
            : mode[1] === 'cut y'
            ? ((selected.data.y * dimensions.height) / 100).toFixed(0)
            : selected.opacity
        )
      : undefined

  const selectedValue2 =
    selected && selected?.key !== 0 && mode[0] === 'cut'
      ? Number(
          (mode[1] === 'cut x'
            ? (selected.data.width * dimensions.width) / 100
            : (selected.data.width * selected.data.height * dimensions.width) / 100
          ).toFixed(0)
        )
      : undefined

  const maxValue =
    mode[1] === 'move x'
      ? dimensions.width
      : mode[1] === 'move y'
      ? dimensions.height
      : mode[1] === 'move z'
      ? nextKey < 99
        ? nextKey - 1
        : 98
      : mode[1] === 'rotate'
      ? 360
      : mode[1] === 'scale'
      ? 4
      : mode[1] === 'cut x'
      ? selected?.key
        ? Number(((50 * dimensions.width) / 100).toFixed(0))
        : 0
      : mode[1] === 'cut y'
      ? selected?.key
        ? Number(
            (
              (50 * (inputs.heights[selected.data.id] / inputs.widths[selected.data.id]) * dimensions.width) /
              100
            ).toFixed(0)
          )
        : 0
      : 1

  const minValue = mode[1] === 'scale' ? 0.1 : 0
  const gapValue = mode[1] === 'scale' || mode[1] === 'opacity' ? 0.05 : 1

  const onChange = (value?: number | string) =>
    selected?.key &&
    onSetEdit(
      mode[1] === 'move x'
        ? EditType.X
        : mode[1] === 'move y'
        ? EditType.Y
        : mode[1] === 'move z'
        ? EditType.Z
        : mode[1] === 'rotate'
        ? EditType.ROTATE
        : mode[1] === 'scale'
        ? EditType.SCALE
        : EditType.OPACITY,
      Number(value) > maxValue
        ? mode[1] === 'move x' || mode[1] === 'move y'
          ? 100
          : maxValue
        : Number(value) < minValue
        ? minValue
        : mode[1] === 'move x'
        ? (100 * Number(value)) / dimensions.width
        : mode[1] === 'move y'
        ? (100 * Number(value)) / dimensions.height
        : Number(value)
    )

  const onCut1 = (value: number) => {
    if (selected?.key && mode[0] === 'cut') {
      if (mode[1] === 'cut y') {
        if (selectedValue !== undefined && selectedValue2 !== undefined) {
          const y = (value * 100) / dimensions.height
          onSetEdit(EditType.CUT_Y, y)
          onSetEdit(
            EditType.HEIGHT,
            ((selectedValue2 ?? maxValue) + (selectedValue ?? 0) - value) /
              ((selected.data.width * dimensions.width) / 100)
          )
        }
      } else {
        onSetEdit(EditType.CUT_X, (value * 100) / dimensions.width)
      }
    }
  }

  const onCut2 = (value: number) =>
    selected?.key &&
    mode[0] === 'cut' &&
    (mode[1] === 'cut y'
      ? onSetEdit(EditType.HEIGHT, value / ((selected.data.width * dimensions.width) / 100))
      : onSetEdit(EditType.WIDTH, (100 * value) / dimensions.width))

  return (
    <FixedContainer justify="center">
      <HeaderRow>
        <ButtonLeft
          active={hasEdits ? 'true' : undefined}
          onClick={() =>
            onSelectEdit(
              index === undefined ? Number(keys[keys.length - 1]) : index === 1 ? undefined : Number(keys[index - 1])
            )
          }
        />
        <TYPE.Main fontSize={18} textAlign="center">
          {!selected?.key ? 'TRANSFORM' : mode[0] === 'filter' ? 'FILTER/EFFECTS' : mode[1]}
        </TYPE.Main>
        <ButtonRight
          active={hasEdits ? 'true' : undefined}
          onClick={() =>
            onSelectEdit(
              index === undefined ? Number(keys[1]) : index === keys.length - 1 ? undefined : Number(keys[index + 1])
            )
          }
        />
      </HeaderRow>
      <InfoRow>
        <IconWrapper>
          <RemoveIcon onClick={() => selected?.key && onRemoveEdit(selected.key)} />
        </IconWrapper>
        <IconWrapper>
          <CopyIcon onClick={() => selected && onCopyEdit(selected.key)} />
        </IconWrapper>
        <WidthButton
          active={mode[0] === 'cut'}
          onClick={() => setMode(mode[0] === 'cut' ? ['slide', 'rotate'] : ['cut', 'cut x'])}
        >
          {selectedBG ? (
            <TYPE.Main fontSize={12} textAlign="center">
              width: {Math.round((dimensions.width * selectedBG.data.width * selectedBG.scale) / 100)} height:{' '}
              {Math.round((dimensions.width * selectedBG.data.width * selectedBG.data.height * selectedBG.scale) / 100)}
            </TYPE.Main>
          ) : hasEdits ? (
            <TYPE.Main fontSize={12} textAlign="center">
              width: {dimensions.width} height: {dimensions.height}
            </TYPE.Main>
          ) : (
            <TYPE.Main fontSize={12} textAlign="center">
              {mode[0] === 'width' || mode[0] === 'cut' ? 'Transform here' : 'Cut and flip here'}
            </TYPE.Main>
          )}
          {selected?.key ? (
            <TYPE.Main fontSize={12} textAlign="center">
              width: {((selected.data.width * selected.scale * dimensions.width) / 100).toFixed(0)} height:{' '}
              {((selected.data.width * selected.data.height * selected.scale * dimensions.width) / 100).toFixed(0)}
            </TYPE.Main>
          ) : null}
        </WidthButton>
        <IconWrapper>
          <SliderIcon
            active={mode[0] === 'click' ? mode[0] : undefined}
            onClick={() =>
              mode[0] === 'slide'
                ? setMode(['click', mode[1]])
                : mode[0] === 'click'
                ? setMode(['slide', mode[1]])
                : setMode(['slide', 'rotate'])
            }
          />
        </IconWrapper>
        <IconWrapper>
          <FilterIcon
            active={mode[0] === 'filter' ? mode[0] : undefined}
            onClick={() => setMode(mode[0] === 'filter' ? ['slide', 'rotate'] : ['filter', ''])}
          />
        </IconWrapper>
      </InfoRow>
      <HideContainer hide={mode[0] !== 'width' && mode[0] !== 'cut'} style={{ justifyContent: 'center' }}>
        <RowBetween style={{ maxWidth: '512px' }}>
          <FlipXIcon
            active={selected?.flipY === -1 ? mode[0] : undefined}
            onClick={() => selected?.key && onSetEdit(EditType.FLIP, 1)}
          />
          <IconButtonBorder
            active={mode[0] === 'cut' && mode[1] === 'cut x'}
            Icon={CutXIcon}
            size={48}
            onClick={() => setMode(['cut', 'cut x'])}
          />
          <CloseIcon onClick={() => setMode(['slide', 'rotate'])} />
          <IconButtonBorder
            active={mode[0] === 'cut' && mode[1] === 'cut y'}
            Icon={CutYIcon}
            size={48}
            onClick={() => setMode(['cut', 'cut y'])}
          />
          <FlipYIcon
            active={selected?.flipX === -1 ? mode[0] : undefined}
            onClick={() => selected?.key && onSetEdit(EditType.FLIP, 0)}
          />
        </RowBetween>
      </HideContainer>
      <HideContainer hide={mode[0] !== 'slide' && mode[0] !== 'click'} style={{ justifyContent: 'center' }}>
        <RowBetween style={{ maxWidth: '512px', height: '48px' }}>
          <IconButtonBorder
            active={mode[1] === 'move x'}
            Icon={Code}
            size={48}
            onClick={() => setMode([mode[0], 'move x'])}
          />
          <IconButtonBorder
            active={mode[1] === 'move y'}
            Icon={MoveYIcon}
            size={48}
            onClick={() => setMode([mode[0], 'move y'])}
          />
          <IconButtonBorder
            active={mode[1] === 'move z'}
            Icon={MoveZIcon}
            size={48}
            onClick={() => setMode([mode[0], 'move z'])}
          />
          <IconButtonBorder
            active={mode[1] === 'rotate'}
            Icon={RotateCw}
            size={48}
            onClick={() =>
              mode[1] === 'rotate'
                ? selected && onSetEdit(EditType.ROTATE, (selected.rotate - (selected.rotate % 45) + 45) % 360)
                : setMode([mode[0], 'rotate'])
            }
          />
          <IconButtonBorder
            active={mode[1] === 'scale'}
            Icon={Minimize}
            size={48}
            onClick={() => setMode([mode[0], 'scale'])}
          />
          <IconButtonBorder
            active={mode[1] === 'opacity'}
            Icon={OpacityIcon}
            size={48}
            onClick={() => setMode([mode[0], 'opacity'])}
          />
        </RowBetween>
      </HideContainer>

      <HideContainer hide={mode[0] !== 'click'}>
        <IdInputPanel
          value={selectedValue}
          onChange={onChange}
          gapValue={gapValue}
          className="EDIT-TRANSFORM"
          backgroundColor={theme.primary1}
          Icon={
            mode[1] === 'move x'
              ? MoveXIcon
              : mode[1] === 'move y'
              ? MoveYIcon2
              : mode[1] === 'move z'
              ? MoveZIcon
              : mode[1] === 'rotate'
              ? RotateIcon
              : mode[1] === 'scale'
              ? ScaleIcon
              : OpacityIcon
          }
        />
      </HideContainer>
      <HideContainer hide={mode[0] !== 'slide'}>
        <SlideButton
          value={selectedValue}
          maxValue={maxValue}
          onChange={onChange}
          className="Transform"
          placeholder={mode[1]}
        />
      </HideContainer>
      <HideContainer hide={mode[0] !== 'cut'}>
        <SlideButton2
          value={selectedValue}
          value2={selectedValue2}
          maxValue={maxValue}
          onChange={onCut1}
          onChange2={onCut2}
          className="Cutter"
          placeholder={mode[1]}
        />
      </HideContainer>
      <HideContainer height="112px" hide={mode[0] !== 'filter'}>
        <FilterPanel className="Layer" selected={selected?.key ? selected : undefined} onSetFilter={onSetFilter} />
      </HideContainer>
    </FixedContainer>
  )
}
