import { createAction } from '@reduxjs/toolkit'

import { EditType, FilterType, InputType } from './types'

export const addEdit = createAction<{ ratio?: number; inputId?: number }>('user/addEdit')

export const clearEdits = createAction('user/clearEdits')

export const copyEdit = createAction<{ key: number }>('user/copyEdit')

export const removeEdit = createAction<{ key: number }>('user/removeEdit')

export const selectEdit = createAction<{ key?: number }>('user/selectEdit')

export const setEdit = createAction<{ type: EditType; value: number }>('user/setEdit')

export const setFilter = createAction<{ type: FilterType; value: number }>('user/setFilter')

export const setPosEdit = createAction<{ x: number; y: number }>('user/setPosEdit')

export const addInput = createAction<{ data: string; width: number; height: number; isVector: boolean }>(
  'user/addInput'
)

export const setInput = createAction<{ type: InputType; key: number; value: string | number }>('user/setInput')

export const removeInput = createAction<{ key: number }>('user/removeInput')

export const setBackground = createAction<{ key?: number | string }>('user/setBackground')

export const setDimensions = createAction<{ width: number; height: number; widthClient: number; heightClient: number }>(
  'user/setDimensions'
)
