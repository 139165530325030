import { MouseEvent, TouchEvent, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

import { TYPE } from '../../theme'
import { RowFixed } from '../Row'

const SlideWrapper = styled.div<{ background?: string }>`
  background: ${({ background, theme }) => (background ? background : theme.advancedBG)};
  min-width: 44px;
  padding-top: 4px;
  align-items: center;
  height: 44px;
  border-radius: 9999px;
`

const Slider = styled.div<{ background?: string; isLeft?: boolean }>`
  background: ${({ background, theme }) => (background ? background : theme.advancedBG)};
  min-width: 24px;
  padding-top: 4px;
  align-items: center;
  user-select: none;
  height: 44px;
  border-left: 2px solid ${({ isLeft, theme }) => (isLeft ? 'none' : theme.bg3)};
  border-top-left-radius: ${({ isLeft }) => (isLeft ? '9999px' : 'none')};
  border-bottom-left-radius: ${({ isLeft }) => (isLeft ? '9999px' : 'none')};
  border-top-right-radius: ${({ isLeft }) => (isLeft ? 'none' : '9999px')};
  border-bottom-right-radius: ${({ isLeft }) => (isLeft ? 'none' : '9999px')};
`

const SlideWrapper2 = styled.div<{ background?: string }>`
  height: 44px;
  min-width: 24px;
`

const SlideContainer = styled.div`
  cursor: pointer;
  border-radius: 9999px;
  border: 2px solid ${({ theme }) => theme.bg3};
  background-color: ${({ theme }) => theme.primary1};
  width: 100%;
  height: 48px;
  text-align: center;
  align-items: center;
  overflow: hidden;
  :hover {
	  border: 2px solid ${({ theme }) => theme.primary4};
    }
  }
  transition: background-color 500ms linear, border 200ms linear;
`

interface SlideButtonProps {
  value?: number
  maxValue: number
  minValue?: number
  gapValue?: number
  noMove?: boolean
  className: string
  placeholder?: string
  background?: string
  onChange: (value: number) => void
}

const SlideButton = ({
  value,
  maxValue,
  minValue = 0,
  onChange,
  className,
  placeholder,
  background,
}: SlideButtonProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const rect = divRef.current?.getBoundingClientRect()

  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      if (!rect) return
      const input = ((e.touches[0].clientX - rect.x - 8) * maxValue) / (rect.width - 16)
      const checksummedInput = input < minValue ? minValue : input > maxValue ? maxValue : input
      onChange(checksummedInput)
    },
    [onChange, rect, maxValue, minValue]
  )

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!rect) return
      const input = ((e.clientX - rect.x - 8) * maxValue) / (rect.width - 16)
      const checksummedInput = input < minValue ? minValue : input > maxValue ? maxValue : input
      onChange(checksummedInput)
    },
    [onChange, rect, maxValue, minValue]
  )

  useEffect(() => {
    const SlideElement = document.getElementById(`SlideWrapper-${className}`)
    if (!SlideElement) return

    SlideElement.style.width = value !== undefined && value < maxValue ? `${(100 * value) / maxValue}%` : '100%'
  }, [maxValue, value])

  return (
    <SlideContainer onClick={handleClick} onTouchMove={handleTouchMove} ref={divRef}>
      <SlideWrapper id={`SlideWrapper-${className}`} background={background}>
        <TYPE.White fontSize={22} style={{ userSelect: 'none' }}>
          {value ?? placeholder ?? 'Select Image'}
        </TYPE.White>
      </SlideWrapper>
    </SlideContainer>
  )
}

export default SlideButton

export const SlideButton2 = ({
  value,
  value2,
  maxValue,
  minValue = 0,
  onChange,
  onChange2,
  className,
  placeholder = '',
}: { value2?: number; onChange2: (value: number) => void } & SlideButtonProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const rect = divRef.current?.getBoundingClientRect()

  const handleTouchMove1 = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      if (!rect) return
      const input = ((e.touches[0].clientX - rect.x - 2) * maxValue) / (rect.width - 16)
      const max = (value ?? 0) + (value2 ?? maxValue) - 4
      const checksummedInput = input < minValue ? minValue : input > max ? max : input
      onChange(checksummedInput)
    },
    [onChange, rect, maxValue, value, value2, minValue]
  )

  const handleTouchMove2 = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      if (!rect) return
      const input = ((e.touches[0].clientX - rect.x - 1) * maxValue) / (rect.width - 16)
      const min = (value ?? 0) + 4
      const checksummedInput = input < min ? min : input > maxValue ? maxValue : input
      onChange2(checksummedInput - (value ?? 0))
    },
    [onChange2, rect, maxValue, value]
  )

  const handleClick1 = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!rect) return
      const input = ((e.clientX - rect.x - 2) * maxValue) / (rect.width - 16)
      const max = (value ?? 0) + (value2 ?? maxValue) - 4
      const checksummedInput = input < minValue ? minValue : input > max ? max : input
      onChange(checksummedInput)
    },
    [onChange, rect, maxValue, value, value2, minValue]
  )

  const handleClick2 = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!rect) return
      const input = ((e.clientX - rect.x - 1) * maxValue) / (rect.width - 16)
      const min = (value ?? 0) + 4
      const checksummedInput = input < min ? min : input > maxValue ? maxValue : input
      onChange2(checksummedInput - (value ?? 0))
    },
    [onChange2, rect, maxValue, value]
  )

  useEffect(() => {
    const SlideElement1 = document.getElementById(`SlideWrapper1-${className}`)
    const SlideElement2 = document.getElementById(`SlideWrapper2-${className}`)
    if (!SlideElement2 || !SlideElement1) return
    const width1 = value !== undefined ? (100 * (value > maxValue ? maxValue : value)) / maxValue : 0
    const width2 = value2 !== undefined ? (100 * (value2 > maxValue ? maxValue : value2)) / maxValue : 100
    SlideElement1.style.width = `${(width1 + width2 / 2).toFixed(4)}%`
    SlideElement2.style.width = `${(100 - (width1 + width2 / 2)).toFixed(4)}%`
    SlideElement1.style.paddingLeft = `${width1.toFixed(4)}%`
    SlideElement2.style.paddingRight = `${(100 - (width1 + width2)).toFixed(4)}%`
  }, [maxValue, value, value2])

  return (
    <SlideContainer ref={divRef}>
      <RowFixed style={{ width: '100%' }}>
        <SlideWrapper2 id={`SlideWrapper1-${className}`} onClick={handleClick1} onTouchMove={handleTouchMove1}>
          <Slider isLeft={true}>
            <TYPE.White fontSize={22} style={{ userSelect: 'none' }}>
              {value !== undefined ? '' : placeholder}
            </TYPE.White>
          </Slider>
        </SlideWrapper2>
        <SlideWrapper2 id={`SlideWrapper2-${className}`} onClick={handleClick2} onTouchMove={handleTouchMove2}>
          <Slider>
            <TYPE.White fontSize={22} style={{ userSelect: 'none' }}>
              {rect && value2 !== undefined ? '' : placeholder}
            </TYPE.White>
          </Slider>
        </SlideWrapper2>
      </RowFixed>
    </SlideContainer>
  )
}
