import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'

export function AdvancedEditDetails() {
  return (
    <AutoColumn gap="md" justify="center" style={{ width: '100%' }}>
      <TYPE.Main>1. Import images and text</TYPE.Main>
      <TYPE.Main>2. Transform your imports</TYPE.Main>
      <TYPE.Main>3. Download image</TYPE.Main>
    </AutoColumn>
  )
}
