import styled from 'styled-components/macro'

import { EditMap, EditType } from '../../state/edit/types'
import Canvas, { CanvasMulti } from '../Canvas'
import InputContainer from './InputContainer'

const ImageContainer = styled.div<{ active?: boolean; height?: number; color?: string }>`
  width: 100%;
  background: ${({ color }) => color};
  height: ${({ height }) => height ?? 256}px;
  outline: 1px solid ${({ active, height, theme }) => (active ? theme.green : theme.primary1)};
  overflow: hidden;
  transition: outline 300ms linear;
`

const ImageDownload = styled.img<{ active?: boolean }>`
  width: 100%;
  //margin-top: -4px;
  height: ${({ active }) => (active ? '100%' : '0')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  transition: opacity 500ms linear;
`

const Test = styled.div`
  width: 100%;
  height: 20px;
  outline: 4px solid ${({ theme }) => theme.green};
  margin-bottom: -5%;
`

interface ImagePanelProps {
  colorBG?: string
  selectedBG?: number
  selected?: number
  hasEdits?: boolean
  edits: EditMap
  inputs: { urls: string[]; widths: number[]; heights: number[]; types: boolean[] }
  onSelectEdit: (key?: number) => void
  onSetEdit: (type: EditType, value: number) => void
  onSetPosEdit: (x: number, y: number) => void
  handleChangeDimension: (width?: number, height?: number, widthClient?: number, heightClient?: number) => void
  handleDownload: (url: string) => void
  dimensions: { width: number; height: number; widthClient: number; heightClient: number }
  isDownload: boolean
  downloadURL?: string
}

export default function ImagePanel({
  dimensions,
  colorBG,
  selectedBG,
  selected,
  edits,
  inputs,
  onSelectEdit,
  onSetEdit,
  onSetPosEdit,
  handleChangeDimension,
  handleDownload,
  isDownload,
  downloadURL,
  hasEdits,
}: ImagePanelProps) {
  return (
    <ImageContainer
      id="editimage"
      active={isDownload}
      height={hasEdits || selectedBG !== undefined ? dimensions.heightClient : undefined}
      color={colorBG}
    >
      <InputContainer
        dimensions={dimensions}
        show={!isDownload}
        edits={edits}
        inputs={inputs}
        selected={selected}
        onSelectEdit={onSelectEdit}
        onSetEdit={onSetEdit}
        onSetPosEdit={onSetPosEdit}
      />
      <Canvas
        selectedBG={edits[0]}
        data={selectedBG === undefined || isDownload ? undefined : inputs.urls[selectedBG]}
        onChange={handleChangeDimension}
        maxWidth={
          selectedBG !== undefined
            ? inputs.types[selectedBG]
              ? dimensions.width > dimensions.height
                ? dimensions.width
                : dimensions.height
              : undefined
            : undefined
        }
        x={(edits[0].data.x * dimensions.width) / 100}
        y={(edits[0].data.y * dimensions.width) / 100}
        show={!isDownload}
        dimensions={dimensions}
        className="CONTAINER"
      />
      <div style={{ top: '64px', right: '8px', left: '8px', position: 'absolute' }}>
        <ImageDownload src={isDownload ? downloadURL : undefined} active={isDownload && !!downloadURL} />
        <CanvasMulti
          show={isDownload}
          width={dimensions.width}
          height={dimensions.height}
          colorBG={colorBG}
          edits={edits}
          inputs={inputs}
          selectedBG={selectedBG}
          handleDownload={handleDownload}
          className="DOWNLOAD"
        />
      </div>
    </ImageContainer>
  )
}
