import { PopoverClickContent } from 'components/Tooltip'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { ImageImportPanel } from '../meme/ImageImportPanel'

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  stroke: ${({ theme }) => theme.primary4};
  :hover {
    opacity: 0.6;
  }
  transition: opacity 300ms linear;
`

export function Form({
  onLoad,
  width,
  className,
}: {
  onLoad: (value: string, width: number, height: number, isVector: boolean) => void
  width?: number
  className: string
}) {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    if (fileList && fileList.length > 0) {
      const img = new Image()
      img.addEventListener(
        'load',
        () => {
          onLoad(
            img.src,
            width ?? img.width,
            width ? (width * img.height) / img.width : img.height,
            fileList[0].type === 'image/svg+xml'
          )
        },
        { once: true }
      )

      img.src = URL.createObjectURL(fileList[0])
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  return (
    <form id={`IMAGE-FORM-${className}`} onSubmit={handleSubmit} style={{ opacity: '1' }}>
      <StyledInput className={`FILE-INPUT-${className}`} type="file" accept="image/*" onChange={handleFileChange} />
    </form>
  )
}

export default function UploadButton({
  width,
  height,
  mode,
  inputs,
  onLoad,
  onBackground,
  onSelect,
  className,
  children,
}: {
  inputs?: { urls: string[]; widths: number[]; heights: number[] }
  width?: number
  height?: string
  mode?: string
  onLoad: (value: string, width: number, height: number, isVector?: boolean) => void
  onBackground?: (key?: number) => void
  onSelect?: (inputId?: number) => void
  className?: string
  children: React.ReactNode
}) {
  const [open, setOpen] = useState(false)

  return (
    <PopoverClickContent
      isOpen={open}
      wrap={false}
      width="352px"
      origin="top"
      content={
        <ImageImportPanel
          className={`Button${className}`}
          onBackground={onBackground}
          onSelect={onSelect}
          onLoad={onLoad}
          width={width}
          inputs={inputs}
          maxHeight={height}
          mode={mode}
        />
      }
      placement="top"
    >
      <div onClick={() => setOpen(!open)}>{children}</div>
    </PopoverClickContent>
  )
}
