import { AutoColumn } from 'components/Column'
import { useState } from 'react'
import { ChevronLeft } from 'react-feather'
import styled from 'styled-components/macro'

import useTheme from '../../hooks/useTheme'
import { Edit, FilterType } from '../../state/edit/types'
import { TYPE } from '../../theme'
import SlideButton from '../Button/SlideButton'
import { RowBetween } from '../Row'

const FixedContainer = styled(AutoColumn)`
  width: 100%;
  height: 104px;
  align-items: center;
`

const ButtonContainer = styled(RowBetween)`
  width: 288px;
  top: -8px;
  height: 40px;
  position: relative;
`

const ButtonLeft = styled(ChevronLeft)`
  width: 40px;
  height: 40px;
  stroke: ${({ theme }) => theme.primary1};
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
  transition: opacity 300ms linear;
`

const ButtonRight = styled(ButtonLeft)`
  rotate: 180deg;
`

const ImageWrapper = styled.img<{ filter?: string }>`
  max-width: 256px;
  height: 80px;
  filter: ${({ filter }) => (filter ? filter : 'none')};
`

interface FilterPanelProps {
  selected?: Edit
  className: string
  onSetFilter: (type: FilterType, value: number) => void
}

function getFilterID(filter: FilterType): number {
  return filter === FilterType.NONE
    ? 0
    : filter === FilterType.BLUR
    ? 1
    : filter === FilterType.BRIGHTNESS
    ? 2
    : filter === FilterType.CONTRAST
    ? 3
    : filter === FilterType.SHADOW
    ? 4
    : filter === FilterType.GRAYSCALE
    ? 5
    : filter === FilterType.ROTATE
    ? 6
    : filter === FilterType.INVERT
    ? 7
    : filter === FilterType.SATURATE
    ? 8
    : 9
}

export default function FilterPanel({ selected, className, onSetFilter }: FilterPanelProps) {
  const theme = useTheme()

  const [mode, setMode] = useState(selected ? getFilterID(selected.filter.filter) : 0)

  const filters = [
    FilterType.NONE,
    FilterType.BLUR,
    FilterType.BRIGHTNESS,
    FilterType.CONTRAST,
    FilterType.SHADOW,
    FilterType.GRAYSCALE,
    FilterType.ROTATE,
    FilterType.INVERT,
    FilterType.SATURATE,
    FilterType.SEPIA,
  ]

  const selectedFilter = filters[mode]

  const selectedValue =
    selectedFilter === selected?.filter.filter
      ? Number(selected.filter.value)
      : selectedFilter === FilterType.SATURATE ||
        selectedFilter === FilterType.CONTRAST ||
        selectedFilter === FilterType.BRIGHTNESS
      ? 100
      : 0

  const maxValue =
    selectedFilter === FilterType.NONE
      ? 0
      : selectedFilter === FilterType.BRIGHTNESS
      ? 200
      : selectedFilter === FilterType.SHADOW
      ? 32
      : selectedFilter === FilterType.BLUR
      ? 16
      : selectedFilter === FilterType.ROTATE
      ? 359
      : 100

  const minValue = 0
  const gapValue = 1

  const onChange = (value?: number | string) =>
    selected &&
    onSetFilter(
      selectedFilter,
      Number(value) > maxValue ? maxValue : Number(value) < minValue ? minValue : Number(value)
    )

  return (
    <FixedContainer justify="center">
      <ButtonContainer>
        <ButtonLeft onClick={() => setMode(mode === 0 ? 9 : mode - 1)} />
        <TYPE.Main textAlign="center" style={{ userSelect: 'none' }}>
          {selected ? selectedFilter : 'Select Input'}
        </TYPE.Main>
        <ButtonRight onClick={() => setMode(mode === 9 ? 0 : mode + 1)} />
      </ButtonContainer>

      <SlideButton
        value={selectedFilter === FilterType.NONE ? undefined : selected && selectedValue}
        maxValue={maxValue}
        onChange={onChange}
        className={`Filter${className}`}
        placeholder={selectedFilter}
      />
    </FixedContainer>
  )
}
