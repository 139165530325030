import { createAction } from '@reduxjs/toolkit'

export type RectCV = {
  x: number
  y: number
  width: number
  height: number
}

export const addInput = createAction<{ data: string; width: number; height: number }>('maker/addInput')

export const removeInput = createAction('maker/removeInput')

export const setIndex = createAction<{ index: number; value: number }>('maker/setIndex')

export const setFounded = createAction<{ eyes?: RectCV[] }>('maker/setFounded')
