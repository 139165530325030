import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components/macro'

import XMLFile from '../assets/xml/haarcascade_eye.xml'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Popups from '../components/Popups'
import { RouteDefinition, routes, useRouterConfig } from './RouteDefinitions'

const AppWrapper = styled.div`
  display: flex;
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  min-height: 100%;
  text-transform: lowercase;
  background-image: linear-gradient(${({ theme }) => theme.primary1 + 'd0'}, ${({ theme }) => theme.primary1 + 'd0'}),
    linear-gradient(45deg, #d227f4, #10efa7);
  //justify-content: center;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  position: absolute;
  padding: 64px 64px 32px 64px;
  align-items: center;

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding: 64px 4px 64px 4px;
  }
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  height: 0px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 99;
`

//<Route component={GoogleAnalyticsReporter} />

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const routerConfig = useRouterConfig()
  const cv = window.cv

  useEffect(() => {
    if (!cv) return

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    //const XMLFile = require('../assets/xml/haarcascade_eye.xml')
    function createFileFromUrl(path: string, url: string, callback: () => void) {
      const request = new XMLHttpRequest()
      request.open('GET', url, true)
      request.responseType = 'arraybuffer'
      request.onload = function (ev) {
        if (request.readyState === 4) {
          if (request.status === 200) {
            const data = new Uint8Array(request.response)
            cv.FS_createDataFile('/', path, data, true, false, false)
            callback()
          } else {
            throw Error('Failed to load ' + url + ' status: ' + request.status)
          }
        }
      }
      request.send()
    }
    createFileFromUrl('haarcascade_eye.xml', XMLFile, () => null)
  }, [cv])

  return (
    <ErrorBoundary>
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>

        <BodyWrapper>
          <Popups />
          <Routes>
            {routes.map((route: RouteDefinition) =>
              route.enabled(routerConfig) ? (
                <Route key={route.path} path={route.path} element={route.getElement(routerConfig)}>
                  {route.nestedPaths.map((nestedPath) => (
                    <Route path={nestedPath} key={`${route.path}/${nestedPath}`} />
                  ))}
                </Route>
              ) : null
            )}
          </Routes>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </ErrorBoundary>
  )
}
