import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { AppState } from '../index'
import {
  addEdit,
  addInput,
  clearEdits,
  copyEdit,
  removeEdit,
  removeInput,
  selectEdit,
  setBackground,
  setDimensions,
  setEdit,
  setFilter,
  setInput,
  setPosEdit,
} from './actions'
import { EditType, FilterType, InputType } from './types'

export function useEditState(): AppState['edit'] {
  return useAppSelector((state) => state.edit)
}

export function useEditActionHandlers(): {
  onAddInput: (data: string, width: number, height: number, isVector?: boolean) => void
  onSetInput: (type: InputType, key: number, value: string | number) => void
  onRemoveInput: (key: number) => void
  onAddEdit: (ratio?: number, inputId?: number) => void
  onClearEdits: () => void
  onCopyEdit: (key: number) => void
  onEditSelection: (key?: number) => void
  onRemoveEdit: (key: number) => void
  onSetEdit: (type: EditType, value: number) => void
  onSetFilter: (type: FilterType, value: number) => void
  onSetPosEdit: (x: number, y: number) => void
  onSetBackground: (key?: number | string) => void
  onSetDimensions: (width: number, height: number, widthClient: number, heightClient: number) => void
} {
  const dispatch = useAppDispatch()

  const onAddInput = useCallback(
    (data: string, width: number, height: number, isVector?: boolean) => {
      dispatch(addInput({ data, width, height, isVector: !!isVector }))
    },
    [dispatch]
  )

  const onSetInput = useCallback(
    (type: InputType, key: number, value: string | number) => {
      dispatch(setInput({ type, key, value }))
    },
    [dispatch]
  )

  const onRemoveInput = useCallback(
    (key: number) => {
      dispatch(removeInput({ key }))
    },
    [dispatch]
  )

  const onAddEdit = useCallback(
    (ratio?: number, inputId?: number) => {
      dispatch(addEdit({ ratio, inputId }))
    },
    [dispatch]
  )

  const onClearEdits = useCallback(() => {
    dispatch(clearEdits())
  }, [dispatch])

  const onCopyEdit = useCallback(
    (key: number) => {
      dispatch(copyEdit({ key }))
    },
    [dispatch]
  )

  const onEditSelection = useCallback(
    (key?: number) => {
      dispatch(selectEdit({ key }))
    },
    [dispatch]
  )

  const onRemoveEdit = useCallback(
    (key: number) => {
      dispatch(removeEdit({ key }))
    },
    [dispatch]
  )

  const onSetEdit = useCallback(
    (type: EditType, value: number) => {
      dispatch(setEdit({ type, value }))
    },
    [dispatch]
  )

  const onSetFilter = useCallback(
    (type: FilterType, value: number) => {
      dispatch(setFilter({ type, value }))
    },
    [dispatch]
  )

  const onSetPosEdit = useCallback(
    (x: number, y: number) => {
      dispatch(setPosEdit({ x, y }))
    },
    [dispatch]
  )

  const onSetBackground = useCallback(
    (key?: number | string) => {
      dispatch(setBackground({ key }))
    },
    [dispatch]
  )

  const onSetDimensions = useCallback(
    (width: number, height: number, widthClient: number, heightClient: number) => {
      dispatch(setDimensions({ width, height, widthClient, heightClient }))
    },
    [dispatch]
  )

  return {
    onAddInput,
    onSetInput,
    onRemoveInput,
    onAddEdit,
    onClearEdits,
    onCopyEdit,
    onEditSelection,
    onRemoveEdit,
    onSetEdit,
    onSetFilter,
    onSetPosEdit,
    onSetBackground,
    onSetDimensions,
  }
}
