import { useCallback, useContext, useState } from 'react'
import { Check, Droplet, Grid, Trash, Type } from 'react-feather'
import styled, { ThemeContext } from 'styled-components/macro'

import { Edit, EditType } from '../../state/edit/types'
import { TYPE } from '../../theme'
import { IconButtonBorder } from '../Button/IconButton'
import SlideButton from '../Button/SlideButton'
import { AutoColumn } from '../Column'
import IdInputPanel from '../IdInputPanel'
import { RowBetween, RowFixed } from '../Row'
import { Input as StringInput } from '../StringInput'
import StyledText from './text'

const ImportPanel = styled(AutoColumn)`
  padding: 0 4px 4px 8px;
  position: relative;
  height: 212px;
  border-radius: 12px;
  align-items: center;
  width: 100%;
`

const HideContainer = styled.div<{ hide?: boolean }>`
  gap: 8px;
  width: 100%;
  height: ${({ hide }) => (hide ? '0px' : '52px')};
  overflow: hidden;
  transition: height 200ms linear;
`

const SelectPanel = styled(RowBetween)`
  gap: 8px;
  position: relative;
  height: 44px;
  align-items: center;
  width: 100%;
`

const EditPanel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 40px;
  max-width: 100%;
`

const ColorInput = styled.div<{ color: string; active?: boolean }>`
  background: ${({ color }) => color};
  border: 2px solid ${({ active, theme }) => (active ? theme.primary4 : '#333333')};
  width: 48px;
  height: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
    transition: border 200ms linear;
  }
`

const ColorPanel = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  position: relative;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: 52px;
`

const ImportOption = styled.div<{ backgroundColor?: string; active?: boolean }>`
  padding-top: 2px;
  background: ${({ active, backgroundColor, theme }) =>
    active ? (backgroundColor ? backgroundColor : theme.advancedBG) : 'transparent'};
  border: 2px solid ${({ active, theme }) => (active ? theme.primary4 : theme.bg3)};
  cursor: pointer;
  border-radius: 16px;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  width: 100%;
  min-height: 36px;
  overflow: hidden;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
  }
  transition: border 200ms linear;
`

const CheckIcon = styled(Check)`
  stroke: ${({ theme }) => theme.primary4};
  justify-self: center;
  min-width: 36px;
  min-height: 36px;
  margin-left: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const RemoveIcon = styled(Trash)`
  stroke: ${({ theme }) => theme.primary4};
  justify-self: center;
  min-width: 32px;
  min-height: 32px;
  margin-right: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const IconWrapper = styled.div`
  width: 44px;
`

interface TextProps {
  textData: string[]
  size: number
  color: string
  background: string
  border: string
  borderWidth: number
  borderRadius: string
  strokeWidth: number
  stroke: string
  letterSpace: string
  width: number
  height: number
  key?: number
}

export default function TextInputPanel({
  selected,
  selectedBG,
  onLoad,
  onRemoveEdit,
  onRemoveInput,
  onSetEdit,
  onAddEdit,
  dimensions,
  nextKey,
  inputs = { urls: [], widths: [], heights: [] },
}: {
  selected?: Edit
  selectedBG?: number
  dimensions: { width: number; height: number; widthClient: number; heightClient: number }
  nextKey: number
  inputs?: { urls: string[]; widths: number[]; heights: number[] }
  onLoad: (value: string, width: number, height: number, isEdit?: boolean, isVector?: boolean) => void
  onRemoveEdit: (key: number) => void
  onRemoveInput: (key: number) => void
  onSetEdit: (type: EditType, value: number) => void
  onAddEdit: (inputId: number) => void
}) {
  const theme = useContext(ThemeContext)
  const [option, setOption] = useState(['text', 'fill', ''])

  const [inputKey, setInputKey] = useState<number | undefined>(undefined)

  const [textProps, setTextProps] = useState<TextProps>({
    textData: [],
    width: dimensions.width / 2,
    height: 0,
    size: 32,
    color: '#6c7c98',
    background: '#4c5c78',
    borderWidth: 2,
    border: '#5c6c88',
    borderRadius: '16px',
    stroke: '#acbcd8',
    strokeWidth: 1,
    letterSpace: '.1em',
    key: undefined,
  })

  const handleChange = useCallback(
    (data: TextProps) => {
      if (!inputKey || !selected) return
      const url = URL.createObjectURL(new Blob([StyledText(data)], { type: 'image/svg+xml;charset=utf-8' }))
      const thisedit = selected
      onRemoveEdit(selected.key)
      onRemoveInput(inputKey)
      onLoad(url, data.width + data.borderWidth, data.height + data.borderWidth, selectedBG !== undefined, true)
      setInputKey(inputs.urls.length)
      onSetEdit(EditType.Y, selected.y)
      onSetEdit(EditType.X, selected.x)
      onSetEdit(EditType.Z, selected.z)
      onSetEdit(EditType.ROTATE, selected.rotate)
      onSetEdit(EditType.SCALE, selected.scale)
      onSetEdit(EditType.OPACITY, selected.opacity)
    },
    [inputKey, selected, onLoad, onRemoveEdit, onRemoveInput, setInputKey, textProps]
  )

  const onChange = (value?: number | string) => {
    const data =
      option[0] === 'text'
        ? option[1] === 'size'
          ? { ...textProps, height: (textProps.height * Number(value)) / textProps.size, size: Number(value) }
          : { ...textProps, strokeWidth: Number(value) }
        : option[1] === 'size'
        ? { ...textProps, width: Number(value) }
        : { ...textProps, borderWidth: Number(value) }
    selected && setTextProps(data)
    selected && handleChange(data)
  }

  const handleEnter = useCallback(
    (value: string) => {
      const newText = textProps.textData.length === 0 || !selected || !textProps.key || selected.key !== textProps.key
      const newWidth = Math.round((value.length + 2) * textProps.size * 0.8)
      const isEmpty = value == ''
      const data = isEmpty
        ? { ...textProps, key: nextKey }
        : {
            ...textProps,
            textData: newText ? [value] : [...textProps.textData, value],
            width: newWidth > textProps.width || newText ? newWidth : textProps.width,
            height: (newText ? 2 : textProps.textData.length + 2) * (textProps.size + 8),
            key: nextKey,
          }

      const url = URL.createObjectURL(new Blob([StyledText(data)], { type: 'image/svg+xml;charset=utf-8' }))
      if (newText || isEmpty) {
        onLoad(url, data.width + data.borderWidth, data.height + data.borderWidth, selectedBG !== undefined, true)
        setInputKey(inputs.urls.length)
      } else {
        if (!inputKey) return
        onRemoveInput(inputKey)
        onRemoveEdit(selected.key)
        onLoad(url, data.width + data.borderWidth, data.height + data.borderWidth, selectedBG !== undefined, true)
        setInputKey(inputs.urls.length)
      }
      setTextProps(data)
    },
    [inputKey, onLoad, onRemoveEdit, onRemoveInput, setInputKey, textProps, selected, selectedBG, nextKey, inputs]
  )

  const handleRGBSelect = useCallback(
    (value: number) => {
      const color = `hsl(${Math.round((359 * value) / 100)}, 100%, 50%)`
      const data =
        option[0] === 'text'
          ? option[1] === 'fill'
            ? { ...textProps, color }
            : { ...textProps, stroke: color }
          : option[1] === 'fill'
          ? { ...textProps, background: color }
          : { ...textProps, border: color }
      setTextProps(data)
      handleChange(data)
    },
    [textProps, option, handleChange]
  )

  const handleBWSelect = useCallback(
    (value: number) => {
      const colorID = Math.round((255 * value) / 100)
      const color = `rgb(${colorID}, ${colorID}, ${colorID})`
      const data =
        option[0] === 'text'
          ? option[1] === 'fill'
            ? { ...textProps, color }
            : { ...textProps, stroke: color }
          : option[1] === 'fill'
          ? { ...textProps, background: color }
          : { ...textProps, border: color }
      setTextProps(data)
      handleChange(data)
    },
    [textProps, option, handleChange]
  )

  function getColors() {
    const list: JSX.Element[] = []
    const colors = [
      'url(#lgText)',
      theme.primary1,
      theme.primary2,
      'black',
      'white',
      'blue',
      'yellow',
      'green',
      'orange',
      'red',
      'transparent',
    ]
    colors.map((color, i) => {
      const isGradient = color === 'url(#lgText)'
      list.push(
        <ColorInput
          key={`COLOR-INPUT-${i}`}
          color={isGradient ? theme.advancedBG : color}
          active={
            !!textProps.key &&
            (option[0] === 'text'
              ? option[1] === 'fill'
                ? color === textProps.color
                : option[1] === 'stroke'
                ? color === textProps.stroke
                : false
              : option[1] === 'fill'
              ? color === textProps.background
              : option[1] === 'stroke'
              ? color === textProps.border
              : false)
          }
          onClick={() => {
            const data =
              option[0] === 'text'
                ? option[1] === 'fill'
                  ? { ...textProps, color }
                  : { ...textProps, stroke: color }
                : option[1] === 'fill'
                ? { ...textProps, background: color }
                : { ...textProps, border: color }
            setTextProps(data)
            handleChange(data)
          }}
        />
      )
    })

    return list
  }

  const gradientRGB =
    'linear-gradient(to right, hsl(0, 100%, 50%), hsl(60, 100%, 50%), hsl(120, 100%, 50%), hsl(180, 100%, 50%), hsl(240, 100%, 50%), hsl(300, 100%, 50%), hsl(359, 100%, 50%))'

  const gradientBW = 'linear-gradient(to right, black, white)'
  return (
    <ImportPanel>
      <TYPE.Main fontSize={18} textAlign="center" mt="-12px" mb="-16px">
        TEXT
      </TYPE.Main>
      <SelectPanel>
        <ImportOption active={option[0] === 'text'} onClick={() => setOption(['text', option[1], option[2]])}>
          <TYPE.White>text</TYPE.White>
        </ImportOption>
        <ImportOption active={option[0] === 'frame'} onClick={() => setOption(['frame', option[1], option[2]])}>
          <TYPE.White>frame</TYPE.White>
        </ImportOption>
        <IconWrapper>
          <IconButtonBorder
            Icon={option[1] === 'size' || option[1] === 'width' ? Droplet : Type}
            size={44}
            onClick={() =>
              setOption(
                option[1] === 'size' || option[1] === 'width'
                  ? [option[0], 'fill', option[2]]
                  : [option[0], 'size', option[2]]
              )
            }
          />
        </IconWrapper>
        <ImportOption
          active={option[1] === 'width' || option[1] === 'stroke'}
          onClick={() =>
            setOption([option[0], option[1] === 'size' || option[1] === 'width' ? 'width' : 'stroke', option[2]])
          }
        >
          <TYPE.White>stroke</TYPE.White>
        </ImportOption>
        <ImportOption
          active={option[1] === 'size' || option[1] === 'fill'}
          onClick={() =>
            setOption([option[0], option[1] === 'stroke' || option[1] === 'fill' ? 'fill' : 'size', option[2]])
          }
        >
          <TYPE.White>{option[1] === 'size' || option[1] === 'width' ? 'size' : 'fill'}</TYPE.White>
        </ImportOption>
      </SelectPanel>
      <EditPanel>
        {option[1] === 'size' || option[1] === 'width' ? (
          <IdInputPanel
            maxValue={4096}
            value={
              textProps.key
                ? option[0] === 'text'
                  ? option[1] === 'size'
                    ? textProps.size
                    : textProps.strokeWidth
                  : option[1] === 'size'
                  ? Number(textProps.width)
                  : textProps.borderWidth
                : undefined
            }
            onChange={onChange}
            className="EDIT-TEXT-INPUT"
            backgroundColor={theme.primary1}
          />
        ) : (
          <AutoColumn style={{ width: '100%', height: '52px' }}>
            <HideContainer hide={option[2] !== ''}>
              <ColorPanel>
                <ColorInput color={gradientRGB} onClick={() => setOption([option[0], option[1], 'rgb'])} />
                {getColors()}
              </ColorPanel>
            </HideContainer>
            <HideContainer hide={option[2] === ''}>
              <RowBetween style={{ width: '100%', gap: '8px' }}>
                <ColorInput
                  color={option[2] === 'bw' ? gradientRGB : gradientBW}
                  onClick={() => setOption([option[0], option[1], option[2] === 'bw' ? 'rgb' : 'bw'])}
                />
                <div style={{ width: '100%' }}>
                  <SlideButton
                    maxValue={100}
                    onChange={option[2] === 'bw' ? handleBWSelect : handleRGBSelect}
                    className="TextColor"
                    placeholder="Select Color"
                    background={option[2] === 'bw' ? gradientBW : gradientRGB}
                  />
                </div>
                <ColorInput color={theme.primary1} onClick={() => setOption([option[0], option[1], ''])}>
                  <Grid size={44} color={theme.primary2 + '77'} />
                </ColorInput>
              </RowBetween>
            </HideContainer>
          </AutoColumn>
        )}
      </EditPanel>
      <RowFixed style={{ width: '100%', height: '36px' }}>
        <RemoveIcon
          onClick={() => {
            selected && onRemoveEdit(selected.key)
            textProps?.key && setTextProps({ ...textProps, key: undefined })
          }}
        />
        <StringInput
          className="Text"
          onEnter={handleEnter}
          maxHeight="48px"
          backgroundColor={theme.primary1}
          placeholder="Write a text line here"
        />
        <CheckIcon
          onClick={() => {
            textProps?.key && setTextProps({ ...textProps, key: undefined })
          }}
        />
      </RowFixed>
    </ImportPanel>
  )
}
