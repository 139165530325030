import { useCallback, useState } from 'react'
import { Plus, Trash, X } from 'react-feather'
import styled from 'styled-components/macro'

import { IconButtonBorder } from '../../components/Button/IconButton'
import UploadButton from '../../components/Button/UploadButton'
import { AutoColumn } from '../../components/Column'
import { EyesDisclaimer } from '../../components/Disclaimer'
import { AdvancedEyeDetails } from '../../components/eye/AdvancedEyeDetails'
import EyeHeader from '../../components/eye/EyeHeader'
import ImagePanel from '../../components/eye/ImagePanel'
import ImportPanel from '../../components/eye/ImportPanel'
import { RowBetween } from '../../components/Row'
import { useEyeActionHandlers, useEyeState } from '../../state/eye/hooks'
import AppBody from '../AppBody'

const HeaderButtonWrapper = styled(RowBetween)<{ show1?: boolean; show2?: boolean }>`
  align-items: center;
  position: absolute;
  top: 12px;
  height: 40px;
  border-bottom: 2px solid ${({ theme }) => theme.bg3};
  border-top: 2px solid ${({ theme }) => theme.bg3};
  border-radius: 24px;
  max-width: ${({ show1, show2 }) => (show1 && show2 ? '256px' : '128px')};
  left: 50%;
  transform: ${({ show1, show2 }) => (show1 && !show2 ? 'translate(-100%)' : 'translate(-50%)')};
  transition: border 300ms linear, background-color 300ms linear, transform 300ms linear, max-width 300ms linear;
`

const UploadWrapper = styled.div`
  padding: 6px;
  top: -28px;
  border: 8px solid ${({ theme }) => theme.bg4};
  border-radius: 9999px;
  position: absolute;
  height: 128px;
  margin-left: 31%;
  opacity: 0.2;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  transition: opacity 300ms linear;
`

const PlusUpload = styled(Plus)`
  stroke: ${({ theme }) => theme.bg3};
  width: 92px;
  height: 92px;
`

export default function Maker() {
  const { input, indexes, eyes } = useEyeState()
  const { onAddInput, onRemoveInput, onSetIndex, onSetFounded } = useEyeActionHandlers()

  const [isDownload, setDownload] = useState(input ? true : false)

  const handleAddInput = useCallback(
    (value: string, width: number, height: number, isEdit?: boolean) => {
      onAddInput(value, width, height)
      setDownload(true)
    },
    [onAddInput]
  )

  const handleRemoveInput = useCallback(() => {
    onRemoveInput()
    setDownload(false)
  }, [onRemoveInput])
  return (
    <>
      <AppBody id="maker-page" margin="-3rem">
        <AutoColumn gap="sm" justify="center" style={{ width: '100%' }}>
          <EyeHeader />
          <HeaderButtonWrapper show1={!!input} show2={false}>
            <IconButtonBorder
              Icon={isDownload ? X : Trash}
              size={48}
              onClick={() => {
                !!input && handleRemoveInput()
              }}
            />
          </HeaderButtonWrapper>
          <ImagePanel input={input} isDownload={isDownload} indexes={indexes} eyes={eyes} onFound={onSetFounded} />
          <ImportPanel
            eyes={eyes}
            indexes={indexes}
            input={input}
            handleAddInput={handleAddInput}
            handleSetIndex={onSetIndex}
            isDownload={isDownload}
          />
          <div style={{ position: 'absolute', justifySelf: 'center', top: '148px' }}>
            <UploadWrapper>
              <UploadButton height="0" mode="input" className="Container" onLoad={handleAddInput}>
                <PlusUpload />
              </UploadButton>
            </UploadWrapper>
            <AdvancedEyeDetails />
          </div>
        </AutoColumn>
      </AppBody>
      <EyesDisclaimer />
      <div style={{ height: '96px' }} />
    </>
  )
}
