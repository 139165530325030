import { t } from '@lingui/macro'
import { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { isBrowserRouterEnabled } from 'utils/env'

import Eye from './Eye'
import Home from './Home'
import { RedirectPathToHomeOnly } from './Home/redirects'

interface RouterConfig {
  browserRouterEnabled?: boolean
  hash?: string
}

/**
 * Convenience hook which organizes the router configuration into a single object.
 */
export function useRouterConfig(): RouterConfig {
  const browserRouterEnabled = isBrowserRouterEnabled()
  const { hash } = useLocation()
  return useMemo(
    () => ({
      browserRouterEnabled,
      hash,
    }),
    [browserRouterEnabled, hash]
  )
}

export interface RouteDefinition {
  path: string
  nestedPaths: string[]
  staticTitle: string
  enabled: (args: RouterConfig) => boolean
  getElement: (args: RouterConfig) => ReactNode
}

// Assigns the defaults to the route definition.
function createRouteDefinition(route: Partial<RouteDefinition>): RouteDefinition {
  return {
    getElement: () => null,
    staticTitle: 'Trade Dove App',
    enabled: () => true,
    path: '/',
    nestedPaths: [],
    // overwrite the defaults
    ...route,
  }
}

export const routes: RouteDefinition[] = [
  createRouteDefinition({
    path: '/',
    getElement: () => <RedirectPathToHomeOnly />,
  }),
  createRouteDefinition({
    path: '/meme',
    getElement: () => <Home />,
    staticTitle: t`Broser based Image Editor`,
  }),
  createRouteDefinition({
    path: '/eye',
    getElement: () => <Eye />,
    staticTitle: t`Trade Dove eyes generator`,
  }),
  createRouteDefinition({ path: '*', getElement: () => <RedirectPathToHomeOnly /> }),
]
