import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { AlertTriangle, X } from 'react-feather'
import { useDisclaimerEditor, useDisclaimerEyes } from 'state/application/hooks'
import styled from 'styled-components/macro'
import { TYPE } from 'theme'

const AlertIcon = styled(AlertTriangle)`
  width: 16px;
  height: 16px;
  stroke: ${({ theme }) => theme.white};
`

const Body = styled(AutoColumn)`
  gap: 8px;
  font-size: 12px;
  grid-column: 1 / 3;
  text-align: center;
  line-height: 100%;
  margin: 0;
`

const CloseIcon = styled(X)`
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  right: 8px;
  :hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
`

const RootWrapper = styled.div`
  position: relative;
  padding-top: 12px;
`

const ContentWrapper = styled.div`
  background-image: linear-gradient(${({ theme }) => theme.primary2 + 'b0'}, ${({ theme }) => theme.primary2 + 'b0'}),
    linear-gradient(45deg, #d227f4, #10efa7);
  border-radius: 12px;
  padding: 4px 8px 12px 8px;
  display: flex;
  flex-direction: column;
  max-width: 512px;
  min-height: 60px;
  overflow: hidden;
  position: relative;
  width: 100%;
  :before {
    content: '';
    height: 100px;
    opacity: 0.1;
    position: absolute;
    transform: rotate(25deg) translate(-90px, -40px);
    width: 100%;
  }
`

const Header = styled(RowFixed)`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 4px;
  gap: 16px;
  color: ${({ theme }) => theme.white};
`

export function EditorDisclaimer() {
  const [editorDismissed, setEditorDimissed] = useDisclaimerEyes()

  return !editorDismissed ? (
    <RootWrapper>
      <ContentWrapper>
        <CloseIcon onClick={setEditorDimissed} />
        <Header>
          <TYPE.White>DISCLAIMER</TYPE.White>
        </Header>
        <Body>
          <TYPE.White>
            This is a 100% web browser based Image Editor. Nothing is send to anything that also means this interface
            does not collect any kind of data.
          </TYPE.White>
        </Body>
      </ContentWrapper>
    </RootWrapper>
  ) : null
}

export function EyesDisclaimer() {
  const [eyesDismissed, setEyesDimissed] = useDisclaimerEditor()

  return !eyesDismissed ? (
    <RootWrapper>
      <ContentWrapper>
        <CloseIcon onClick={setEyesDimissed} />
        <Header>
          <TYPE.White>DISCLAIMER</TYPE.White>
        </Header>

        <Body>
          <TYPE.White>
            This generator use OpenCV to find eyes in images. This works not perfect but is open source and runs in your
            web browser. No image is send to any server!
          </TYPE.White>
        </Body>
      </ContentWrapper>
    </RootWrapper>
  ) : null
}
