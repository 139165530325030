/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function StyledText({
  textData,
  width = '100%',
  height = '100%',
  size = 16,
  color = 'url(#lgText)',
  background = '#7040E0',
  borderWidth = 2,
  border = 'grey',
  borderRadius = '8px',
  stroke = 'grey',
  strokeWidth = 2,
  letterSpace = '.1em',
}: {
  textData: string[]
  size?: number
  color?: string
  background?: string
  border?: string
  borderWidth?: number
  borderRadius?: string
  strokeWidth?: number
  stroke?: string
  letterSpace?: string
  width?: string | number
  height?: string | number
}): string {
  function getLines() {
    let lines = ''
    textData.map((value, i) => {
      lines = lines + `<text x="50%" y="${(size + 8) * (i + 1)}">${value}</text>`
    })
    return lines
  }
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${
      typeof width === 'number' ? width + borderWidth : width
    }" height="${typeof height === 'number' ? height + borderWidth : height}" viewBox="0 0 ${
    typeof width === 'number' ? width + borderWidth : width
  } ${typeof height === 'number' ? height + borderWidth : height}">
      <defs>
        <linearGradient id="lgText" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="20%" stop-color="#30bfa3" stop-opacity="1" />
          <stop offset="80%" stop-color="#a247c4" stop-opacity="1" />
        </linearGradient>
      </defs>
	  <g stroke-linejoin="round" stroke-linecap="round">
	    <rect x="${borderWidth / 2}" y="${
    borderWidth / 2
  }" width="${width}" height="${height}" rx="${borderRadius}" fill="${background}" stroke="${border}" stroke-width="${borderWidth}">
	    </rect>
		<g transform="translate(0, ${
      size / 3
    })" fill="${color}" stroke-width="${strokeWidth}" letter-spacing="${letterSpace}" stroke="${stroke}" font-size="${size}" font-family="Comic Sans MS" text-anchor="middle">
		  ${getLines()}
        </g>
	  </g>
	  
    </svg>
  `
}
