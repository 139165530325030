import { KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { ChevronsLeft, ChevronsRight, Icon } from 'react-feather'
import styled from 'styled-components/macro'

import { TYPE } from '../../theme'
import IconButton from '../Button/IconButton'
import { Input as NumericalInput } from '../NumericalInput'
import Row, { RowFixed } from '../Row'

const InputPanel = styled(RowFixed)`
  position: relative;
  border-radius: '12px';
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  padding-left: 4px;
  width: 100%;
`

const IconWrapper = styled.div`
  stroke: ${({ theme }) => theme.primary2};
  justify-self: center;
  position: absolute;
  width: 32px;
  height: 32px;
  z-index: 0;
  bottom: 0px;
`

interface InputInfoProps {
  value?: number
  className?: string
  title?: string
  placeholder?: string
  gapValue?: number
  minValue?: number
  maxValue?: number
  isUser?: boolean
  backgroundColor?: string
  onChange: (value?: number | string) => void
  onChecksummed?: (value: number) => number
  Icon?: Icon
}

const InputRow = styled(Row)<{ backgroundColor?: string }>`
  ${({ theme }) => theme.flexRowNoWrap}
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'Transparent')};
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.bg3};
  align-items: center;
  text-align: center;
  margin-right: 4px;
  margin-left: 4px;
  overflow: hidden;
  max-width: 128px;
  :hover,
  :focus {
    border: 2px solid ${({ theme }) => theme.primary4};
  }
  transition: border 200ms linear;
`

export default function IdInputPanel({
  value,
  Icon,
  gapValue = 1,
  maxValue,
  minValue = 0,
  isUser,
  title,
  placeholder,
  backgroundColor,
  className = 'ID-PANEL',
  onChange,
}: InputInfoProps) {
  const [input, setInput] = useState('')

  const handleInput = useCallback(
    (value: string) => {
      const checksummedInput = maxValue
        ? Number(value) < minValue
          ? minValue.toString()
          : Number(value) > maxValue
          ? maxValue.toString()
          : value
        : value
      setInput(checksummedInput)
    },
    [maxValue, minValue]
  )

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onChange(Number(input))
      }
    },
    [onChange, input]
  )

  useEffect(() => {
    setInput(value?.toString() ?? '')
  }, [isUser, value])

  return (
    <InputPanel className={className}>
      <IconButton
        Icon={ChevronsLeft}
        onClick={() => {
          const checksummedValue =
            value === undefined ? undefined : value <= minValue + gapValue ? minValue : value - gapValue
          onChange(checksummedValue)
          setInput(checksummedValue ? checksummedValue.toString() : '')
        }}
      />
      <InputRow backgroundColor={backgroundColor}>
        {title ? (
          <TYPE.Main fontSize={20} ml="8px" mr="8px">
            {title}:
          </TYPE.Main>
        ) : null}
        <NumericalInput
          value={placeholder ?? input}
          id={className + '-INPUT'}
          fontSize="20px"
          onKeyDown={handleEnter}
          placeholder={placeholder ?? value?.toString()}
          backgroundColor={backgroundColor}
          onChange={handleInput}
          style={{ padding: '0 4px 0 0' }}
        />
      </InputRow>
      <IconButton
        Icon={ChevronsRight}
        onClick={() => {
          const checksummedValue =
            value === undefined
              ? undefined
              : maxValue
              ? value >= maxValue - gapValue
                ? maxValue
                : value + gapValue
              : value + gapValue
          onChange(checksummedValue)
          setInput(checksummedValue ? checksummedValue.toString() : '')
        }}
      />
      {Icon ? (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      ) : null}
    </InputPanel>
  )
}
