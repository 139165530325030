import React, { forwardRef } from 'react'
import styled from 'styled-components/macro'

import { escapeRegExp } from '../../utils'

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string; backgroundColor?: string }>`
  color: ${({ error, theme }) => (error ? theme.red1 : theme.white)};
  width: 0;
  position: relative;
  font-weight: 600;
  outline: none;
  border: none;
  z-index: 1;
  flex: 1 1 auto;
  background-color: ${({ backgroundColor, theme }) => (backgroundColor ? 'Transparent' : theme.primary1)};
  transition: background-color 500ms linear;
  font-size: ${({ fontSize }) => fontSize ?? '24px'};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;
  text-align: right;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'> {
  value?: string | number
  onChange: (value: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
  backgroundColor?: string
  prependSymbol?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, placeholder, backgroundColor, prependSymbol, ...rest }: InputProps, ref) => {
    const enforcer = (nextUserInput: string) => {
      if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
        onChange(nextUserInput)
      }
    }

    return (
      <StyledInput
        {...rest}
        ref={ref}
        backgroundColor={backgroundColor}
        value={prependSymbol && value ? prependSymbol + value : value}
        onChange={(event) => {
          if (prependSymbol) {
            const value = event.target.value

            // cut off prepended symbol
            const formattedValue = value.toString().includes(prependSymbol)
              ? value.toString().slice(1, value.toString().length + 1)
              : value

            // replace commas with periods, because uniswap exclusively uses period as the decimal separator
            enforcer(formattedValue.replace(/,/g, '.'))
          } else {
            enforcer(event.target.value.replace(/,/g, '.'))
          }
        }}
        // universal input options
        inputMode="decimal"
        autoComplete="off"
        autoCorrect="off"
        // text-specific options
        type="text"
        pattern="^[0-9]*$"
        placeholder={placeholder || '0'}
        minLength={0}
        maxLength={5}
        spellCheck="false"
      />
    )
  }
)
Input.displayName = 'Input'

const MemoizedInput = React.memo(Input)
export { MemoizedInput as Input }
