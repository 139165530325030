import { useState } from 'react'
import { ChevronLeft, Trash } from 'react-feather'
import styled from 'styled-components/macro'

import { useIsMobile } from '../../hooks/useIsMobile'
import { TYPE } from '../../theme'
import { Form } from '../Button/UploadButton'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'

const ImportPanel = styled(AutoColumn)`
  padding: 0 0 4px 0;
  gap: 4px;
  position: relative;
  align-items: center;
  width: 100%;
`

const InputPanel = styled.div<{ maxHeight?: string }>`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: ${({ maxHeight }) => maxHeight};
`

const InputImage = styled.img<{ isMobile?: boolean }>`
  border: 2px solid ${({ theme }) => theme.bg4};
  height: 72px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
    transition: border 200ms linear;
  }
`

const OptionPanel = styled(RowBetween)`
  gap: 8px;
  position: relative;
  border-radius: 12px;
  width: 100%;
  height: 48px;
`

const ImportOption = styled.div<{ backgroundColor?: string }>`
  padding-top: 4px;
  background: ${({ backgroundColor, theme }) => (backgroundColor ? backgroundColor : theme.advancedBG)};
  border: 2px solid ${({ theme }) => theme.bg3};
  cursor: pointer;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 18px;
  width: 100%;
  height: 40px;
  overflow: hidden;
  :hover {
    border: 2px solid ${({ theme }) => theme.primary4};
    transition: border 200ms linear;
  }
`

const BackIcon = styled(ChevronLeft)`
  stroke: ${({ theme }) => theme.primary4};
  min-width: 32px;
  min-height: 32px;
  cursor: pointer;
  transition: rotate 300ms linear;
  :hover {
    opacity: 0.6;
    transition: rotate 300ms linear opacity 300ms linear;
  }
`

const RemoveIcon = styled(Trash)`
  stroke: ${({ theme }) => theme.primary4};
  margin-right: 8px;
  min-width: 32px;
  min-height: 32px;
  cursor: pointer;
  transition: rotate 300ms linear;
  :hover {
    opacity: 0.6;
    transition: rotate 300ms linear opacity 300ms linear;
  }
`

export function ImageImportPanel({
  onLoad,
  onBackground,
  onRemove,
  onSelect,
  selected,
  width,
  maxHeight,
  inputs = { urls: [], widths: [], heights: [] },
  showRemove,
  mode,
  className,
}: {
  selected?: number
  width?: number
  maxHeight?: string
  inputs?: { urls: string[]; widths: number[]; heights: number[] }
  onLoad: (value: string, width: number, height: number, isEdit?: boolean, isVector?: boolean) => void
  onBackground?: (key?: number) => void
  onRemove?: (key: number) => void
  onSelect?: (inputId?: number) => void
  className: string
  mode?: string
  showRemove?: boolean
}) {
  const [option, setOption] = useState(mode ?? '')
  const isMobile = useIsMobile()

  showRemove = (showRemove && option === 'bg') || (!!selected && option === 'import')

  const handleOnSelect = (value: number) => {
    if (onSelect && value < inputs.urls.length) {
      onSelect(value)
    }
  }

  function getInputs() {
    const list: JSX.Element[] = []
    inputs &&
      inputs.urls.map((src, i) => {
        if (src) {
          list.push(
            <InputImage
              key={`IMAGE-INPUT-${i}`}
              src={src}
              isMobile={isMobile}
              onClick={() => option !== '' && (option === 'bg' ? onBackground && onBackground(i) : handleOnSelect(i))}
            />
          )
        }
      })

    return list
  }

  return (
    <ImportPanel>
      <TYPE.Main fontSize={18} textAlign="center" mb="4px" mt="-8px">
        {option === '' ? 'IMPORT AS' : 'SELECT IMAGE'}
      </TYPE.Main>
      {inputs ? <InputPanel maxHeight={maxHeight}>{getInputs()}</InputPanel> : null}
      {option === '' ? (
        <OptionPanel>
          <ImportOption onClick={() => setOption('bg')}>
            <TYPE.White>BACKGROUND</TYPE.White>
          </ImportOption>
          <ImportOption onClick={() => setOption('import')}>
            <TYPE.White textAlign="center">LAYER</TYPE.White>
          </ImportOption>
        </OptionPanel>
      ) : (
        <OptionPanel>
          {!mode ? <BackIcon onClick={() => setOption('')} /> : null}
          <ImportOption>
            <div
              style={{
                opacity: '0',
                position: 'absolute',
                left: '36px',
                right: showRemove ? '36px' : '0',
              }}
            >
              <Form
                className={`${option === 'bg' ? 'BG' : 'Edit'}${className}`}
                onLoad={(value: string, width: number, height: number, isVector: boolean) => {
                  onLoad(value, width, height, option === 'import', isVector)
                  option !== 'import' && setOption('')
                }}
                width={width}
              />
            </div>
            <TYPE.White>SELECT OR DROP</TYPE.White>
          </ImportOption>
          {showRemove ? (
            <RemoveIcon
              onClick={() =>
                option === 'import'
                  ? onRemove && selected && onRemove(selected)
                  : onBackground && onBackground(undefined)
              }
            />
          ) : null}
        </OptionPanel>
      )}
    </ImportPanel>
  )
}
